import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import UserContext from "../../UserContext";
import * as FiIcons from "react-icons/fi";

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "main-sidebar__link active" : "main-sidebar__link",
      };
    }}
  />
);

function MainSidebar(props) {
  const history = useHistory();
  const [activeTabs, setActiveTabs] = useState(props.name);
  const state = useContext(UserContext);
  const [active, setActive] = useState(false);

  useEffect(() => {
    switch (activeTabs) {
      case "Inscrições":
        history.push("/");
        break;
      case "Classificação":
        history.push("#");
        break;
      case "Regulamento":
        history.push("#");
        break;
      case "Recursos":
        history.push("#");
        break;
      default:
        break;
    }
  }, [activeTabs, history]);

  const mobileNavToggle = () => {
    setActive(!active);
  };

  return (
    <div className="main-sidebar">
      <div className="main-sidebar__mobile-hero">
        <div className="main-sidebar__mobile-hero-brand"></div>
        <div className="main-sidebar__mobile-hero-menu" onClick={mobileNavToggle}>
          <FaIcons.FaBars color="#fff" />
        </div>
      </div>
      <div className={`main-sidebar__container ${active ? "active" : ""}`}>
        <div className="main-sidebar__brand">
          <label>Estudologia</label>
          <p>Concessão de Aptidão</p>
        </div>
        <NavLink to="/">
          <div
            className={`main-sidebar__link-name ${history.location.pathname === "/" ? "activenav" : ""} ${history.location.pathname === "/confirmacao-de-email" ? "activenav" : ""} ${
              history.location.pathname === "/finish_subscription" ? "activenav" : ""
            }`}
            onClick={() => setActiveTabs("agenda")}
          >
            <FiIcons.FiHome className="main-sidebar__link-icon" />
            Inscrições
          </div>
        </NavLink>
        <NavLink to="/myinterview">
          <div className={`main-sidebar__link-name ${history.location.pathname === "/myinterview" ? "activenav" : ""}`}>
            <FiIcons.FiCoffee className="main-sidebar__link-icon" />
            Minha entrevista
          </div>
        </NavLink>
        <NavLink to="/approved_list">
          <div className={`main-sidebar__link-name ${history.location.pathname === "/approved_list" ? "activenav" : ""}`}>
            <FiIcons.FiCheckCircle className="main-sidebar__link-icon" />
            Resultado final
          </div>
        </NavLink>
        <NavLink to="/regulation">
          <div className={`main-sidebar__link-name ${history.location.pathname === "/regulation" ? "activenav" : ""}`}>
            <FiIcons.FiFileText className="main-sidebar__link-icon" />
            Acesse a portaria
          </div>
        </NavLink>
        <NavLink to="/resources">
          <div className={`main-sidebar__link-name ${history.location.pathname === "/resources" ? "activenav" : ""} ${history.location.pathname === "/resource-form" ? "activenav" : ""}`}>
            <FiIcons.FiFileText className="main-sidebar__link-icon" />
            Meus Recursos
          </div>
        </NavLink>
        <div className="main-sidebar__bottom-links">
          <NavLink to="/profile">
            <div className={`main-sidebar__link-name ${history.location.pathname === "/profile" ? "activenav" : ""}`}>
              <FaIcons.FaRegUserCircle className="main-sidebar__link-icon" />
              Perfil
            </div>
          </NavLink>
          <NavLink to="/login">
            <div className="main-sidebar__link-name" onClick={() => state.setSession(null)}>
              <FiIcons.FiPower className="main-sidebar__link-icon" />
              Logout
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default MainSidebar;
