import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import UserContext from '../../UserContext'
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';
import _ from 'lodash'
import { Redirect } from "react-router-dom";
import * as FiIcon from "react-icons/fi";
import { AiOutlineMail } from 'react-icons/ai';

function FinishedInscription(props) {
  const state = useContext(UserContext)
  const client = useClient();
  const [subscription, setSubscription] = useState();
  const currenUser = state.session.user;

  useEffect(() => {
    if(currenUser?.subscriptionId === null) {
      return <Redirect noThrow to="/" />
    } else {
      getSubscription();
    }
  }, []);

  const getSubscription = async () => {
    try {
      const { data } = await client.fetch(`subscriptions/${currenUser.subscriptionId}`)
      console.log(data);
      setSubscription(data)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="home-content">
          <div className="home-content__pannel-content">
            <div className="inscription-status">Status do processo: <b>{subscription?.['t-status']}</b></div>
            <div className="pannel-content">
              <div className="pannel-content__abilities">
                {subscription && subscription?.['user-skills'].map((userSkill) => (
                  <div className="pannel-content__ability" key={userSkill.id}>
                    <div className="pannel-content__ability-header"> {userSkill['skill-area']} <b>{userSkill['skill-name']}</b></div>
                    <div className="pannel-content__ability-status">
                      <div className="pannel-content__ability-status-text"> {userSkill['t-status']}</div>
                      {userSkill.status === 'approved_interview' && <Link to="/myinterview" className="btn btn--small btn--outline">Entrevista agendada</Link>}
                    </div>
                    <div className="pannel-content__documents">
                      {userSkill.documents.map((document) => (
                        <div className="pannel-content__document" key={document.id}>
                          <div className="pannel-content__document-title">{document.filename}</div>
                          <div className={`pannel-content__document-status ${document.status}`}>{document['t-status']}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>

          </div>
        </div>
      </div>

    </div>
    </div>
  )
}
export default FinishedInscription
