import React, { useState, useEffect } from "react";
import { useClient } from "jsonapi-react";
import MainNav from "../components/MainNav";
import MainSidebar from "../components/MainSidebar";
import ResourceDocumentCard from "../components/ResourceDocumentCard";
import Preloader from "../components/Preloader";
import ModalResource from "../components/ModalResource";
import { FiFileText, FiCheckCircle, FiCloudOff } from "react-icons/fi";
import ReactHtmlParser from "react-html-parser";
import toast from "react-hot-toast";
import _ from "lodash";

function ResourceForm(props) {
  const client = useClient();
  const resourceId = props?.computedMatch?.params?.id;
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [currentResource, setCurrentResource] = useState();
  const [resourceDocuments, setResourceDocuments] = useState();

  useEffect(() => {
    fetchCurrentResource();
    fetchResourceDocuments();
  }, []);

  const fetchCurrentResource = async () => {
    try {
      const { data } = await client.fetch(`resources/${resourceId}`);
      if (data) {
        setCurrentResource(data);
        setUserComment(data["user-comment"] || "");
        if (resourceDocuments) {
          setTimeout(() => {
            setIsLoading(false);
          }, 50);
        }
      } else {
        window.location.pathname = "resources";
      }
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const fetchResourceDocuments = async () => {
    try {
      const { data } = await client.fetch(`resources/${resourceId}/resource_documents`);
      setResourceDocuments([...data]);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const handleChangeImage = (e) => {
    e.target.files.forEach((file) => {
      let file_name = file.name;
      let file_type = file.type;

      if (file) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this, file_name, file_type);
        reader.readAsBinaryString(file);
      }
    });
  };

  const _handleReaderLoaded = (name, type, readerEvt) => {
    let bynaryString = readerEvt.target.result;
    let auxValues = values;
    let fileName = name.split(".");
    auxValues["filename"] = fileName[0];
    auxValues["file"] = `data:${type};base64,${btoa(bynaryString)}`;
    setValues({ ...auxValues });
    document.getElementById("documentForm").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const updateDocument = (e) => {
    e.preventDefault();
    const myPromise = client.mutate(["resources", resourceId, "resource_documents"], values);
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        let auxDocuments = resourceDocuments;
        auxDocuments.push(data);
        setResourceDocuments([...auxDocuments]);
        toast.success("Arquivo anexado!");
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`);
      }
    });
  };

  const deleteDocument = async (document) => {
    const { error } = await client.delete(["resources", resourceId, "resource_documents", document.id]);

    if (error) {
      toast.error(error);
      console.log(error);
    } else {
      let auxDocument = resourceDocuments;
      _.remove(auxDocument, (val) => {
        return val.id === document.id;
      });
      setResourceDocuments([...auxDocument]);
      toast.success("Arquivo exluído com sucesso!");
    }
  };

  const openModal = (e) => {
    e.preventDefault();
    if (userComment === "") {
      return toast.error("Recurso não pode ser enviado em branco");
    } else {
      setConfirmModal(true);
    }
  };

  const updateResource = () => {
    const myPromise = client.mutate(["resources", resourceId], { userComment: userComment });
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        toast.success("Recurso enviado com sucesso!");
        setTimeout(() => {
          window.location.pathname = "resources";
        }, 2000);
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`);
      }
    });
  };

  return (
    <div className="main-screen">
      {confirmModal && <ModalResource rejectAction={() => setConfirmModal(false)} acceptAction={() => updateResource()} />}
      <MainSidebar />
      <MainNav />
      <div className="main-content">
        {isLoading ? (
          <Preloader />
        ) : (
          // ) : currentResource?.status === "created" ? (
          //   <div className="empty-state empty-state--full">
          //     <div className="empty-state__body">
          //       <FiCloudOff size={35} />
          //       <div className="empty-state__title">Período de interposição de recursos finalizado</div>
          //     </div>
          //   </div>
          // ) : (
          <>
            <div className="main-content__header">
              <div className="email-instructions">
                <p>
                  <b>Atenção:</b> O servidor interessado poderá interpor recurso para qualquer fase do processo (análise documental e banca de entrevista), uma única vez, junto à Subsecretaria de Educação Básica ou à Subsecretaria de Educação
                  Inclusiva e Integral a depender da área pleiteada, até o dia 27/09.
                  <br />
                  <br />
                  O servidor deverá preencher o recurso de forma clara, objetiva e consistente e fará suas alegações e considerações acerca da avaliação a que foi submetido, podendo anexar documentos que embasam suas colocações.
                  <br />
                  <br />O recurso será analisado, inicialmente, pela banca examinadora para o exercício do poder de reconsideração. Uma vez não reconsiderada a decisão da banca examinadora, o recurso será submetido à decisão final da SUBIN, para
                  casos da Educação Especial, Centro Interescolar de Línguas, Escola Parque, Escola da Natureza e Educação em Tempo Integral (Ensino Médio em Tempo Integral e Programa Intercultural Bilíngue) e da SUBEB, nos demais casos.
                </p>
              </div>
            </div>

            {currentResource?.status === "created" ? (
              <div className="resource-form__textarea form">
                <textarea className="interview-form" id="comments" name="comments" placeholder="Digite aqui o recurso" value={userComment} onChange={(e) => setUserComment(e.target.value)} />
                <div className="btn btn--secondary btn--xlarge" onClick={openModal}>
                  Enviar e voltar
                </div>
              </div>
            ) : (
              <>
                <div className="resource-form__textarea text">
                  <p>{ReactHtmlParser(currentResource["user-comment"].replaceAll("\n", "<br/>"))}</p>
                </div>

                <div className="pannel-content__ability">
                  <div className="pannel-content__ability-header">
                    {" "}
                    {currentResource["skill-area"]} <b>{currentResource["skill-name"]}</b>
                  </div>
                  <div className="pannel-content__ability-status"> {currentResource["t-status"]} </div>
                  {currentResource?.status === "rejected" && <div className="pannel-content__documents">{currentResource["admin-comment"]}</div>}
                </div>
              </>
            )}

            <div className="resource-form__document-list">
              {currentResource?.status === "created" && (
                <form className="form" id="documentForm" method="post" onSubmit={(e) => updateDocument(e)}>
                  <label className="btn btn--primary btn--large btn--full" htmlFor="image">
                    Anexar documentos
                    <input type="file" name="image" id="image" accept=".jpeg, .png, .jpg, .pdf" multiple onChange={handleChangeImage} />
                  </label>
                  <p>
                    Somente arquivos nas extensões: <br />
                    ".jpg", ".jpeg", ".png" e ".pdf"
                  </p>
                </form>
              )}

              {resourceDocuments && resourceDocuments.length > 0 ? (
                resourceDocuments.map((document, index) => (
                  <div key={index} className="archive-upload-card archive-upload-card--resource">
                    <header className="archive-upload-card__header">
                      <div className="archive-upload-card__iconsvg">
                        <FiFileText size={27} />
                      </div>
                      <div className="archive-upload-card__checkicon">
                        <FiCheckCircle size={18} />
                      </div>
                      <div className="archive-upload-card__group">
                        <h2 className="archive-upload-card__title">{document.filename}</h2>
                        <div className="archive-upload-card__filetype">{_.upperCase(document["file-extension"])}</div>
                      </div>
                    </header>
                    <div className="archive-upload-card__footer">
                      {currentResource?.status === "created" && (
                        <button className="btn btn--outline btn--small" onClick={() => deleteDocument(document)}>
                          Excluir
                        </button>
                      )}
                      <button className="btn btn--outline btn--small">
                        <a href={document.file} target="blank" className="main-nav__help">
                          Visualizar
                        </a>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35} />
                    <div className="empty-state__title">Nenhum documento anexado</div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default ResourceForm;
