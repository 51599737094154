import React, { useState, useEffect } from 'react';
import AdminSidebar from "../components/AdminSidebar";
import AdminMainNav from '../components/AdminMainNav';
import FilterBarApproved from '../components/FilterBarApproved';
import Preloader from '../components/Preloader'
import { FiCloudOff } from "react-icons/fi";
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';
import _ from 'lodash'

function AdminHome(){
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [skillAreaResults, setSkillAreaResults] = useState([])
  const [filteredSkillAreaResults, setFilteredSkillAreaResults] = useState([]);

  useEffect(() => {
    fetchDataSkillAreaResults();
  }, []);

  const fetchDataSkillAreaResults = async () => {
    try {
      const { data } = await client.fetch('admin/user_skills_results')
      setSkillAreaResults([...data])
      setTimeout(() => {
        setIsLoading(false)
        setFilterLoaded(true)
      }, 100);
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const filterCallback = (selectedArea, selectedSkill) => {
    setIsLoading(true)
    let auxSkillAreaResults = skillAreaResults
    console.log(auxSkillAreaResults.length, selectedArea, selectedSkill);

    if(selectedSkill) {
      auxSkillAreaResults = _.filter(auxSkillAreaResults, (r) => {return selectedSkill.id === r.id });
    } else if (selectedArea) {
      auxSkillAreaResults = _.filter(auxSkillAreaResults, (r) => {return selectedArea.id === r['skill-area-id'] });
    }

    setFilteredSkillAreaResults(auxSkillAreaResults)
    setTimeout(() => { setIsLoading(false) }, 100);
  }

  return (
    <div className="main-screen">
      <AdminSidebar activeMenu="resultadoPreliminar" />
      <AdminMainNav/>
      <div className="main-content">
        <div className="home-content">
          {filterLoaded && <FilterBarApproved filterCallback={filterCallback} />}

          {isLoading ? <Preloader /> : (
            (filteredSkillAreaResults && filteredSkillAreaResults.length > 0) ? (
              filteredSkillAreaResults.map((skill) => (
                <div className="result-card" key={skill.id}>
                  <div className="result-card__head">{skill['skill-area-name']}</div>
                  <div className="result-card__sidebar">
                    <div className="result-card__sidebar-title">Halidade ou Aptidão</div>
                    <div className="result-card__sidebar-subtitle">{skill.name}</div>
                  </div>
                  <div className="result-card__body">
                    <div className="result-card__body-row">
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Nome</div>
                      </div>
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Matrícula</div>
                      </div>
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Resultado final</div>
                      </div>
                    </div>
                    {skill['user-skills'] && skill['user-skills'].map((userSkill) => (
                      <div className="result-card__body-row data-row" key={userSkill.id}>
                        <div className="result-card__body-collumn">{userSkill.user['full-name']}</div>
                        <div className="result-card__body-collumn">{userSkill.user.registration}</div>
                        <div className="result-card__body-collumn">{userSkill['t-status']}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="admin-schedules">
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35}/>
                    <div className="empty-state__title">Nenhum resultado encontrado</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>

  );
}

export default AdminHome;
