import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from '../../UserContext'
import Input from '../components/Input';
import Preloader from '../components/Preloader'
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';
import api from '../services/api.js';
import Footer from "../components/Footer";
import Header from "../components/Header";

function Admin(props){
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const state = useContext(UserContext)

  if (state.admin && Object.keys(state.admin).length !== 0) {
    window.location.pathname = 'admin_home'
  } else {
    setTimeout(() => { setIsLoading(false) }, 50)
  }

  const authenticateUser = (e) => {
    e.preventDefault();
    api.post('authenticate_admin', values).then(response => {
      let normalizedResponse = normalize(response);
      Object.keys(normalizedResponse).map((key) => {
        return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
      })

      state.setAdmin(normalizedResponse);
    }).catch(error => {
      console.error(error)
      toast.error('Matricula ou senha inválidos')
    });
  }

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues(auxValues);
  };

  return (
    <div className="login">
      <Header/>
      <div className="login__screen">
        {isLoading ? <Preloader /> : (
          <div className="login__container">
            <form className="form" method="post" onSubmit={(e) => authenticateUser(e)}>
              <div className="form__row">
                <h1>Acesse sua conta</h1>
              </div>
              <div className="form__row">
                <label htmlFor="registration">Matrícula</label>
                <Input
                  classInput="input__text"
                  placeHolder="Insira sua matrícula"
                  userName='registration'
                  typeInput='text'
                  valueInput={values.registration}
                  onChange={handleChange}
                />
              </div>
              <div className="form__row">
                <label htmlFor="password">Senha</label>
                <Input
                  classInput="input__text"
                  placeHolder="Insira sua senha"
                  userName='password'
                  typeInput='password'
                  valueInput={values.password}
                  onChange={handleChange}
                />
              </div>
              <div className="form__row">
                <input type="submit" value="Continuar" />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
