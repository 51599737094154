import React, { useState, useEffect, useContext } from "react";
import Preloader from "./Preloader";
import UserContext from "../../UserContext";
import { useClient } from "jsonapi-react";
import normalize from "json-api-normalizer";
import toast from "react-hot-toast";
import _ from "lodash";
import { TiTimes } from "react-icons/ti";

function AbilitiesCard(props) {
  const state = useContext(UserContext);
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [skills, setSkill] = useState([]);
  const [selectedArea, setSelectedArea] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    fetchDataAbility();
  }, []);

  useEffect(() => {
    setIsLoading(skills.length === 0);
  }, [skills]);

  const fetchDataAbility = async () => {
    try {
      const { data } = await client.fetch("skill_areas");
      const dataSkills = [...data];
      const allSkills = dataSkills.map((skillArea) => skillArea.skills).flat();
      const selected = _.filter(allSkills, (s) => {
        return state.session.user.skillIds.includes(s.id);
      });
      setSkill(dataSkills);
      setSelectedSkills(selected);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const checkboxChange = (skill) => {
    let auxValues = selectedSkills;

    if (selectedSkills.includes(skill)) {
      _.remove(auxValues, (val) => {
        return val.id === skill.id;
      });
    } else {
      if (auxValues.length >= 3) {
        return toast.error("Escolha no máximo 3 opções");
      }
      auxValues.push(skill);
    }

    setSelectedSkills([...auxValues]);
  };

  const handleSelect = (e) => {
    let selectedIndex = _.findIndex(skills, ["id", parseInt(e.target.value)]);
    setSelectedArea(skills[selectedIndex]);
  };

  const removeSkill = (skill) => {
    let auxValues = selectedSkills;
    _.remove(auxValues, (val) => {
      return val.id === skill.id;
    });
    setSelectedSkills([...auxValues]);
  };

  const updateSkills = (e) => {
    e.preventDefault();
    const values = {
      skill_ids: selectedSkills.map((skill) => skill.id),
    };

    const myPromise = client.mutate(["users", state.session.user.id], values);
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        const forcedResponse = { data: { id: data.id, type: "user", attributes: data } };
        let normalizedResponse = normalize(forcedResponse);
        Object.keys(normalizedResponse).map((key) => {
          return (normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes);
        });
        normalizedResponse.user.authToken = state.session.user?.authToken;
        normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate;
        state.setSession({ user: normalizedResponse.user });
        toast.success("Dados atualizados!");
        props.changeTab("Documentação");
      } else {
        toast.error(`Erro ao atualizar, ${error?.id} ${error?.title}`);
      }
    });
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <div className="side-content--donate">
      <div className="side-content__selected-areas">
        {selectedSkills.length > 0 ? (
          selectedSkills?.map((skill, index) => (
            <p className="side-content__selected-area-item" key={index}>
              {skill.name}{" "}
              <span onClick={() => removeSkill(skill)}>
                <TiTimes size={15} />
              </span>
            </p>
          ))
        ) : (
          <h2>Nenhuma aptidão selecionada, selecione abaixo.</h2>
        )}
      </div>
      <div className="side-content__subtitle"></div>

      {/*<form className="form__donate" method="post">*/}
      <form className="form__donate" method="post" onSubmit={(e) => updateSkills(e)}>
        <div className="form__row">
          <select name="selectedArea" id="selectedArea" onChange={handleSelect}>
            <option>Selecione a área de aptidão</option>
            {skills.map((skill, index) => (
              <option key={skill.id} value={skill.id}>
                {skill.name}
              </option>
            ))}
          </select>
        </div>

        <div className="checkbox-list">
          {selectedArea &&
            selectedArea?.skills.map((areas, index) => {
              const checked = selectedSkills.map((e) => e.id).includes(areas.id);
              return (
                <div className="form__row" key={index}>
                  <div className="checkbox-wrap">
                    <input type="checkbox" id={areas.id} name="skill_id" checked={checked} onChange={() => checkboxChange(areas)} />
                    <label htmlFor={areas.id}>{areas.name}</label>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="tip-message">
          <b>Atenção, antes de prosseguir:</b> você deverá selecionar até no máximo 3 áreas para aptidão.
        </div>

        <div className="form__row">
          <input className="btn btn--full btn--large" type="submit" value="Salvar e prosseguir" />
        </div>
      </form>
    </div>
  );
}

export default AbilitiesCard;
