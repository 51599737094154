import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react'
import UserContext from '../../UserContext'
import InputMask from 'react-input-mask';
import toast  from 'react-hot-toast';
import _ from 'lodash'

function UserPersonalData(props) {
  const {currentSkill} = props;
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [currenUser, setCurrenUser] = useState();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const { data } = await client.fetch(`admin/user_skills/${currentSkill?.id}/user`)
      setCurrenUser(data)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  return (
    <div className="side-content--donate">
      <div className="side-content__title">Dados do cantidato:</div>
      <br></br>
      <div className="side-content__subtitle"></div>

      {currenUser &&
        <form className="form__donate" method="post" onSubmit={(e) => e.preventDefault()}>
          <div className="form__row">
            <label htmlFor="fullName">Nome Completo</label>
            <input type="text" name="fullName" id="fullName" placeholder="Nome Completo" value={currenUser['full-name']} readOnly/>
          </div>

          <div className="form__donate__divcard">
            <div className="form__donate__divcard__input1">
              <label htmlFor="cpf">CPF</label>
              <InputMask type="text" mask="999.999.999-99" name="cpf" id="cpf" placeholder="CPF" value={currenUser.cpf} readOnly/>
            </div>
            <div className="form__donate__divcard__input2">
              <label htmlFor="matricula">Matrícula</label>
              <input type="text" name="matricula" id="matricula" placeholder="Matrícula" value={currenUser.registration} readOnly/>
            </div>
          </div>

          <div className="form__donate__divcard">
            <div className="form__donate__divcard__input1">
              <label htmlFor="phone">Telefone</label>
              <InputMask type="text" mask="(99) 99999-9999" name="phone" id="phone" placeholder="Telefone" value={currenUser.phone} readOnly/>
            </div>
            <div className="form__donate__divcard__input2">
              <label htmlFor="regional_coordination_id">CRE de lotação</label>
              <input type="text" name="regional_coordination_id" id="regional_coordination_id" placeholder="CRE de lotação" value={currenUser['regional-coordination-name']} readOnly/>
            </div>
          </div>

          <div className="form__donate__divcard">
            <div className="form__donate__divcard__input1">
              <label htmlFor="lotacao">Lotação</label>
              <input type="text" name="lotacao" id="lotacao" placeholder="Lotação" value={currenUser.lotacao} readOnly/>
            </div>
            <div className="form__donate__divcard__input2">
              <label htmlFor="fullName">Turno para entrevista</label>
              <input type="text" name="workShift" id="workShift" placeholder="Turno para entrevista" value={currenUser['t-work-shift']} readOnly/>
            </div>
          </div>

        </form>
      }
    </div>
  );
}

export default UserPersonalData;
