import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
import Input from "../components/Input";
import normalize from "json-api-normalizer";
import toast from "react-hot-toast";
import api from "../services/api.js";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useWindowSize from "../utils/WindowSize";

function Login(props) {
  const [values, setValues] = useState({});
  const state = useContext(UserContext);
  if (state.session && Object.keys(state.session).length !== 0) {
    window.location.pathname = "approved_list";
  }

  const authenticateUser = (e) => {
    e.preventDefault();
    api
      .post("authenticate", values)
      .then((response) => {
        let normalizedResponse = normalize(response);
        Object.keys(normalizedResponse).map((key) => {
          return (normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes);
        });

        state.setSession(normalizedResponse);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Matricula ou senha inválidos");
      });
  };

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues(auxValues);
  };

  const { width } = useWindowSize();
  return (
    <div className="login">
      <Header />
      {/* {width > 500 && (
        <>
          <SideNavBar/>
        </>
      )} */}
      <div className="login__start">
        <div className="login__start-container">
          <label>Para começar, acesse ou crie sua conta.</label>
          <Link className="btn btn--primary btn--small" to="/register">
            Criar conta
          </Link>
        </div>
      </div>
      <div className="login__screen">
        <div className="login__container">
          <form className="form" method="post" onSubmit={(e) => authenticateUser(e)}>
            <div className="form__row">
              <h1>Acesse sua conta</h1>
            </div>
            <div className="form__row">
              <label htmlFor="registration">Matrícula</label>
              <Input classInput="input__text" placeHolder="Insira sua matrícula" userName="registration" typeInput="text" valueInput={values.registration} onChange={handleChange} />
            </div>
            <div className="form__row">
              <label htmlFor="password">Senha</label>
              <Input classInput="input__text" placeHolder="Insira sua senha" userName="password" typeInput="password" valueInput={values.password} onChange={handleChange} />
            </div>
            <Link to="/forgot_password" className="rember-link">
              Esqueceu a senha?
            </Link>
            <div className="form__row">
              <input type="submit" value="Continuar" />
            </div>
          </form>
          <div className="login__footer">
            Não possui cadastro? <Link to="/register">Cadastre-se aqui.</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
