import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InputMask from 'react-input-mask';
import toast  from 'react-hot-toast';
import api from '../services/api.js';
import Header from "../components/Header";
import UserContext from '../../UserContext'

function ResetPassword() {
  const useQuery = new URLSearchParams(useLocation().search)

  const [values, setValues] = useState({
    reset_password_token: useQuery.get("reset_password_token")
  })

  const resetPass = async (e) => {
    e.preventDefault();
    if(values.password !== values.paswordConfirmation){
      api.put('/auth/password', values).then(response => {
        toast.success("Sua senha foi alterada")
        setTimeout(() => {
          window.location.pathname = 'login'
        }, 2000)

      }).catch(error => {
        console.error(error)
        toast.error('Não foi possível resetar sua senha, tente novamente mais tarde')
      });
    }else{
      toast.error('Senha e confirmação de senha não são iguais!')
    }
  }

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  return (
    <div className="login">
      <Header/>

      <div className="login__screen">
        <div className="login__container">

          <form className="form" method="post" onSubmit={resetPass}>
            <div className="form__row">
              <h1>Cadastre sua nova senha</h1>
            </div>
            <div className="form__row">
              <label htmlFor="registration">Senha</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleChange}
                placeholder="Insira sua nova senha"
              />
            </div>
            <div className="form__row">
              <label htmlFor="cpf">Repita sua senha</label>
              <input
                name="password_confirmation"
                id="password_confirmation"
                placeholder="Confirme sua nova senha"
                type='password'
                onChange={handleChange}
              />
            </div>

            <div className="form__row">
              <input type="submit" value="Enviar" />
            </div>

          </form>

        </div>
      </div>

    </div>
  )
}
export default ResetPassword
