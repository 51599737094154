import React, { useState, useContext, useEffect } from "react";
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import Preloader from '../components/Preloader'
import UserContext from '../../UserContext'
import { FiCloudOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';
import userAvatar from '../images/user-avatar.svg';
import _ from 'lodash'
import moment from 'moment';
import 'moment/locale/pt';

function MyInterview(props) {
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    fetchDataSchedules();
  }, []);

  const fetchDataSchedules = async () => {
    try {
      const { data, error } = await client.fetch('schedules')
      setSchedules([...data])
      setIsLoading(false)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const confirmSchedule = (schedule) => {
    if(!allowConfirm(schedule)) { return false }
    const myPromise = client.mutate(['schedules', schedule.id], {confirmed: true})
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        let auxSchedules = schedules
        const selectedIndex = _.findIndex(auxSchedules, ['id', parseInt(schedule.id)]);
        auxSchedules[selectedIndex].confirmed = true
        setSchedules([...auxSchedules]);
        toast.success("Status do documento atualizado!")
      } else {
        toast.error(`Erro ao atualizar`)
      }
    });
  }

  const allowConfirm = (schedule) => {
    let dateTime = schedule.time.replace('.000-02:00', '').split('T');
    dateTime[0] = schedule.date
    return moment() < moment(dateTime.join('T')).add(30, 'minutes')
  }

  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="home-content">
          {/*<div className="email-instructions">
            <p><b>Atenção:</b> o prazo para confirmar a entrevista vai até as <b>23h59 do dia 14/09/2021</b><br />O candidato deverá apresentar, no ato da entrevista:<br />– Documento de identificação oficial, com foto;<br />– Em casos em que foi exigido Plano de Trabalho, no ato da inscrição, sugere-se que o candidato leve cópia do mesmo no dia da entrevista.</p>
          </div>*/}
          {isLoading ? <Preloader /> : (
            (schedules && schedules.length > 0) ? (
              schedules.map((schedule, index) => (
                <div key={index} className="admin-schedule">
                  <div className="admin-schedule__when">
                    <div className="admin-schedule__date">{moment(schedule.date).format('DD / MM / YYYY')}</div>
                    <div className="admin-schedule__time">{moment(schedule.time).format('HH:mm')}</div>
                  </div>
                  <div className="admin-schedule__user">
                    <div className="admin-schedule__user-info">
                      <div className="admin-schedule__user-info__name">{schedule['user-skill']['skill-area']}</div>
                      <div className="admin-schedule__user-info__register">{schedule['user-skill']['skill-name']}</div>
                    </div>
                  </div>

                  <div className="admin-schedule__adress">{schedule.address}</div>
                  <div className={`admin-schedule__position ${schedule.status}`}>{schedule['t-status']}</div>
                  <div className="admin-schedule__action">
                    {schedule.confirmed ? (
                      <div className="btn btn--success btn--small">Entrevista Confirmada</div>
                    ) : (
                      allowConfirm(schedule) && <div onClick={() => confirmSchedule(schedule)} className="btn btn--outline btn--outline-strong btn--small">Confirmar entrevista</div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state empty-state--full">
                <div className="empty-state__body">
                  <FiCloudOff size={35}/>
                  <div className="empty-state__title">Nenhum agendamento encontrado</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
export default MyInterview
