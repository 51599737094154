import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../UserContext";
import { useClient } from "jsonapi-react";
import normalize from "json-api-normalizer";
import toast from "react-hot-toast";
import _ from "lodash";

function ModalResource(props) {
  const { rejectAction, acceptAction } = props;

  return (
    <div className="modal">
      <div className="modal__container">
        <div className="modal__header">
          <div className="modal__header-title">Envio de recurso</div>
          <div className="modal__header-subtitle">Estudologia</div>
        </div>
        <div className="modal__body">Tem certeza que enviar o recurso? Uma vez enviado não é possível editar.</div>
        <div className="modal__footer">
          <div className="btn btn--outline-danger" onClick={rejectAction}>
            Ainda não
          </div>
          <div className="btn btn--success" onClick={acceptAction}>
            Finalizar e enviar
          </div>
        </div>
      </div>
      <div className="modal__overlay"></div>
    </div>
  );
}

export default ModalResource;
