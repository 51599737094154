import React, { useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import InputMask from 'react-input-mask';
import toast  from 'react-hot-toast';
import api from '../services/api.js';
import Header from "../components/Header";
import UserContext from '../../UserContext'

function ForgotPassword() {
  const [values, setValues] = useState({})

  const resetPass = async (e) => {
    e.preventDefault();
    api.post('/auth/password', values).then(response => {
      toast.success('O link para resetar sua senha foi enviado para o seu email cadastrado!')
      setTimeout(() => {
        window.location.pathname = 'login'
      }, 2000)
      

    }).catch(error => {
      console.error(error)
      toast.error('Matricula ou CPF inválidos')
    });
  }

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  return (
    <div className="login">
      <Header/>

      <div className="login__screen">
        <div className="login__container">

          <form className="form" method="post" onSubmit={resetPass}>
            <div className="form__row">
              <h1>Insira as suas informações</h1>
            </div>
            <div className="form__row">
              <label htmlFor="registration">Matrícula</label>
              <input
                type="text"
                name="registration"
                id="registration"
                onChange={handleChange}
                placeholder="Insira sua Matrícula"
              />
            </div>
            <div className="form__row">
              <label htmlFor="cpf">CPF</label>
              <InputMask
                mask="999.999.999-99"
                name="cpf"
                id="cpf"
                classInput="input__text"
                placeHolder="Insira seu CPF"
                typeInput='text'
                onChange={handleChange}
              />
            </div>

            <div className="form__row">
              <input type="submit" value="Enviar" />
            </div>

          </form>

        </div>
      </div>

    </div>
  )
}
export default ForgotPassword
