import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { ApiClient, ApiProvider } from "jsonapi-react";
import { Toaster } from "react-hot-toast";
import schema from "./schema";
import { UserProvider } from "./UserContext";
import UserContext from "./UserContext";

import Profile from "./app/screens/Profile";
import "./app/stylesheets/application.sass";
import Login from "./app/screens/Login";
import Admin from "./app/screens/Admin";
import ForgotPassword from "./app/screens/ForgotPassword";
import Home from "./app/screens/Home";
import Register from "./app/screens/Register";
import EmailConfirmation from "./app/screens/EmailConfirmation";
import MyInterview from "./app/screens/MyInterview";
import ApprovedList from "./app/screens/ApprovedList";
import Regulation from "./app/screens/Regulation";
import FinishedInscription from "./app/screens/FinishedInscription";
import AdminHome from "./app/screens/AdminHome";
import AdminSchedules from "./app/screens/AdminSchedules";
import AdminAproved from "./app/screens/AdminAproved";
import UserDocs from "./app/screens/UserDocs";
import Terms from "./app/screens/Terms";
import ResetPassword from "./app/screens/ResetPassword";
import Welcome from "./app/screens/Welcome";
import Resources from "./app/screens/Resources";
import ResourceForm from "./app/screens/ResourceForm";
import AdminResources from "./app/screens/AdminResources";
import AdminResourcesForm from "./app/screens/AdminResourcesForm";

const baseURL = () => {
  if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_PRODUCTION_URL;
  } else {
    return process.env.REACT_APP_DEV_URL;
  }
};
const client = new ApiClient({ url: baseURL(), schema, mediaType: "application/json" });

class PrivateRoute extends React.Component {
  static contextType = UserContext;

  render() {
    let { as: Comp, ...props } = this.props;
    if (this.context.session && Object.keys(this.context.session).length !== 0 && this.context.session?.user?.tokenExpirationDate) {
      const tokenExpirationDate = new Date(this.context.session.user.tokenExpirationDate);
      if (tokenExpirationDate < new Date()) {
        localStorage.setItem("session", null);
        return <Redirect noThrow to="/login" />;
      } else {
        return <Comp {...props} />;
      }
    } else {
      localStorage.setItem("session", null);
      return <Redirect noThrow to="/login" />;
    }
  }
}

class PrivateAdminRoute extends React.Component {
  static contextType = UserContext;

  render() {
    let { as: Comp, ...props } = this.props;
    if (this.context.admin && Object.keys(this.context.admin).length !== 0 && this.context.admin?.admin?.tokenExpirationDate) {
      const tokenExpirationDate = new Date(this.context.admin.admin.tokenExpirationDate);
      if (tokenExpirationDate < new Date()) {
        localStorage.setItem("admin", null);
        return <Redirect noThrow to="/admin" />;
      } else {
        return <Comp {...props} />;
      }
    } else {
      localStorage.setItem("admin", null);
      return <Redirect noThrow to="/admin" />;
    }
  }
}

class App extends React.Component {
  state = {
    session: JSON.parse(localStorage.getItem("session")),
    setSession: (session) => {
      localStorage.setItem("session", JSON.stringify(session));
      this.setState({ session });
    },
    admin: JSON.parse(localStorage.getItem("admin")),
    setAdmin: (admin) => {
      localStorage.setItem("admin", JSON.stringify(admin));
      this.setState({ admin });
    },
    approvedFilters: JSON.parse(localStorage.getItem("approvedFilters")),
    setApprovedFilters: (approvedFilters) => {
      localStorage.setItem("approvedFilters", JSON.stringify(approvedFilters));
      this.setState({ approvedFilters });
    },
    scheduleFilters: JSON.parse(localStorage.getItem("scheduleFilters")),
    setScheduleFilters: (scheduleFilters) => {
      localStorage.setItem("scheduleFilters", JSON.stringify(scheduleFilters));
      this.setState({ scheduleFilters });
    },
    homeFilters: JSON.parse(localStorage.getItem("homeFilters")),
    setHomeFilters: (homeFilters) => {
      localStorage.setItem("homeFilters", JSON.stringify(homeFilters));
      this.setState({ homeFilters });
    },
    resourceFilters: JSON.parse(localStorage.getItem("resourceFilters")),
    setResourceFilters: (resourceFilters) => {
      localStorage.setItem("resourceFilters", JSON.stringify(resourceFilters));
      this.setState({ resourceFilters });
    },
  };

  render() {
    if (this.state.admin && this.state.admin.admin) {
      const authToken = this.state.admin.admin["auth-token"] || this.state.admin.admin.authToken;
      client.addHeader("Authorization", `Bearer ${authToken}`);
    } else if (this.state.session && this.state.session.user) {
      const authToken = this.state.session.user["auth-token"] || this.state.session.user.authToken;
      client.addHeader("Authorization", `Bearer ${authToken}`);
    }

    return (
      <UserProvider value={this.state}>
        <Toaster position="top-center" reverseOrder={false} />
        <ApiProvider client={client}>
          <Router>
            <Switch>
              <Admin exact path="/admin" />
              <Login exact path="/login" />
              <Profile exact path="/profile" />
              <Register exact path="/register" />
              <ResetPassword exact path="/resetar-senha" />
              <PrivateRoute as={Home} exact path="/" />
              <PrivateRoute as={MyInterview} exact path="/myinterview" />
              <PrivateRoute as={EmailConfirmation} exact path="/confirmacao-de-email" />
              <PrivateRoute as={FinishedInscription} exact path="/finish_subscription" />
              <PrivateRoute as={Regulation} exact path="/Regulation" />
              <PrivateRoute as={Resources} exact path="/resources" />
              <PrivateRoute as={ResourceForm} exact path="/resources/:id" />
              <PrivateRoute as={ApprovedList} exact path="/approved_list" />
              <PrivateAdminRoute as={AdminHome} exact path="/admin_home" />
              <PrivateAdminRoute as={AdminSchedules} exact path="/admin_schedules" />
              <PrivateAdminRoute as={AdminAproved} exact path="/admin_aproved" />
              <PrivateAdminRoute as={UserDocs} exact path="/user_docs/:id" />
              <PrivateAdminRoute as={AdminResources} exact path="/admin-resources" />
              <PrivateAdminRoute as={AdminResourcesForm} exact path="/admin-resources/:id" />
              <ForgotPassword exact path="/forgot_password" />
              <Welcome exact path="/welcome" />
              <Terms exact path="/terms" />
            </Switch>
          </Router>
        </ApiProvider>
      </UserProvider>
    );
  }
}
export default App;
