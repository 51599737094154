import React from 'react';

const InputText = ({userName, valueInput, typeInput, onChange, classInput, placeHolder }) => {
  return (
    <div className={classInput}>
      <input
        type={typeInput}
        name={userName}
        placeholder={placeHolder}
        onChange={onChange}
        value={valueInput}
      />
    </div>
  );
};

export default InputText;