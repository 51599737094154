import React, { useContext, useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import UserContext from '../../UserContext'
import toast  from 'react-hot-toast';
import _ from 'lodash'
import moment from 'moment';
import 'moment/locale/pt';

function FilterBarSchedule(props) {
  const {pendingCount, filterCallback, collectionLocations} = props;
  const client = useClient();
  const state = useContext(UserContext)
  const [skillAreas, setSkillAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(state.scheduleFilters?.selectedArea);
  const [selectedSkill, setSelectedSkill] = useState(state.scheduleFilters?.selectedSkill);
  const [selectedStatus, setSelectedStatus] = useState(state.scheduleFilters?.selectedStatus);
  const [selectedDate, setSelectedDate] = useState(state.scheduleFilters?.selectedDate);
  const [selectedLocation, setSelectedLocation] = useState(state.scheduleFilters?.selectedLocation);
  const collectionDates = ["2021-09-15","2021-09-16","2021-09-17","2021-09-20","2021-09-21","2021-09-22","2021-09-23"]

  useEffect(() => {
    fetchDataAbility();
    if(!selectedArea && !selectedSkill && !selectedStatus && !selectedDate && !selectedLocation) {
      filterCallback(selectedArea, selectedSkill, selectedStatus, selectedDate, selectedLocation)
    }
  }, []);

  useEffect(() => {
    if(filterCallback && (selectedArea || selectedSkill || selectedStatus || selectedDate || selectedLocation)) {
      state.setScheduleFilters({
        selectedArea: selectedArea,
        selectedSkill: selectedSkill,
        selectedStatus: selectedStatus,
        selectedDate: selectedDate,
        selectedLocation: selectedLocation
      })
      filterCallback(selectedArea, selectedSkill, selectedStatus, selectedDate, selectedLocation)
    }
  }, [selectedArea, selectedSkill, selectedStatus, selectedDate, selectedLocation]);

  const fetchDataAbility = async () => {
    try {
      const { data } = await client.fetch('skill_areas')
      setSkillAreas([...data])
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const handleSelectArea = (e) => {
    const selectedIndex = _.findIndex(skillAreas, ['id', parseInt(e.target.value)]);
    setSelectedArea(skillAreas[selectedIndex])
    setSelectedSkill(null)
  };

  const handleSelectSkill = (e) => {
    const selectedIndex = _.findIndex(selectedArea.skills, ['id', parseInt(e.target.value)]);
    setSelectedSkill(selectedArea.skills[selectedIndex])
  };

  const handleSelectDate = (e) => {
    setSelectedDate(e.target.value)
  };

  const handleSelectLocation = (e) => {
    setSelectedLocation(e.target.value)
  };

  const clearFilter = () => {
    setSelectedArea(null)
    setSelectedSkill(null)
    setSelectedStatus(null)
    setSelectedDate(null)
    setSelectedLocation(null)
    filterCallback(null, null, null, null, null)
    state.setScheduleFilters(null)
  };

  return (
    <div className="filter-bar filter-bar--schedule form">

      <div className="input__column">
        <label htmlFor="skillArea">Filtre por área</label>
        <select id="skillArea" name="skillArea" value={selectedArea?.id || 'blank'} onChange={handleSelectArea}>
          <option value="blank">Selecione a área de aptidão</option>
          {skillAreas.map((skillArea, index) => (
            <option key={skillArea.id} value={skillArea.id}>{skillArea.name}</option>
          ))}
        </select>
      </div>

      <div className="input__column">
        <label htmlFor="skill">Filtre por aptidão</label>
        <select id="skill" name="skill" value={selectedSkill?.id || 'blank'} onChange={handleSelectSkill}>
          <option value="blank">Selecione uma opção</option>
          {selectedArea && selectedArea?.skills.map((skill, index) => (
            <option key={skill.id} value={skill.id}>{skill.name}</option>
          ))}
        </select>
      </div>



      <div className="input__column">
        <label htmlFor="location">Filtre por local</label>
        <select id="location" name="location" value={selectedLocation || 'blank'} onChange={handleSelectLocation}>
          <option value="blank">Selecione uma opção</option>
          {collectionLocations && collectionLocations.map((location, index) => (
            <option key={index} value={location}>{location}</option>
          ))}
        </select>
      </div>

      <div className="input__column tiny">
        <label htmlFor="date">Filtre por dia</label>
        <select id="date" name="date" value={selectedDate || 'blank'} onChange={handleSelectDate}>
          <option value="blank">Selecione uma opção</option>
          {collectionDates && collectionDates.map((date, index) => (
            <option key={index} value={date}>{moment(date).format('DD/MM/YYYY')}</option>
          ))}
        </select>
      </div>

      <div className="input__column small">
        <input type="radio" id="approved_interview" name="status" checked={selectedStatus === 'done'} onChange={() => setSelectedStatus('done')} />
        <label htmlFor="approved_interview">Concuído</label>
      </div>
      <div className="input__column small">
        <input type="radio" id="pending" name="status" checked={selectedStatus === 'pending'} onChange={() => setSelectedStatus('pending')} />
        <label htmlFor="pending">Pendente (<b>{pendingCount || 0}</b>)</label>
      </div>
      <div className="input__column small">
        <button className="btn btn--primary btn--small btn--outline" onClick={clearFilter}>Limpar filtro</button>
      </div>
    </div>
  )
}

export default FilterBarSchedule
