import React from "react";
import { Link } from 'react-router-dom';
import userAvatar from '../images/user-avatar.svg';

function AdminResourceCard(props) {
  const {resource} = props;

  return (
    <div className="archive-card archive-card--resource">
      <div className="user-card__header">
        <div className="user-card__avatar" style={{ backgroundImage: `url(${resource.user?.avatar || userAvatar})`}}></div>
        <div className="user-card__info"> <b>{resource.user['full-name']}</b> {resource.user.registration}</div>
      </div>
      <br/>
      <header className="archive-card__header">
        <div className="archive-card__group">
          <div className="archive-card__subtitle">{resource['skill-area']}</div>
          <h2 className="archive-card__subtitle">{resource['skill-name']}</h2>
        </div>
      </header>
      <div className="archive-card__body">
        <p className={`archive-card__info ${resource.status === 'sent' ? '' : resource.status}`}>
          <label>{resource.status === 'sent' ? 'Recurso pendente' : resource['t-status']}</label>
        </p>
      </div>
      <div className="archive-card__footer">
        <Link to={`/admin-resources/${resource.id}`} className="btn btn--outline btn--small">Visualizar recurso</Link>
      </div>
    </div>
  )
}

export default AdminResourceCard
