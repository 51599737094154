import React, { useState, useEffect, useContext } from "react";
import Preloader from "./Preloader";
import UserContext from "../../UserContext";
import SkillDocuments from "./SkillDocuments";
import Modal from "./Modal";
import { IoIosClose } from "react-icons/io";
import moment from "moment";
import "moment/locale/pt";
import { useClient, useMutation } from "jsonapi-react";
import toast from "react-hot-toast";
import _ from "lodash";

function ArchiveCard(props) {
  const state = useContext(UserContext);
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSkill, setCurrentSkill] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState({});
  const [skills, setSkills] = useState([]);
  const [addSubscription] = useMutation("subscriptions");

  useEffect(() => {
    fetchDataAbility();
  }, [currentSkill]);

  const fetchDataAbility = async () => {
    try {
      const { data } = await client.fetch("user_skills");
      setSkills([...data]);
      setIsLoading(false);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const deleteUserSkill = async (skill) => {
    console.log(skill.id);
    const { error } = await client.delete(["user_skills", skill.id]);

    if (error) {
      toast.error(error);
      console.log(error);
    } else {
      let auxSkills = skills;
      _.remove(auxSkills, (val) => {
        return val.id === skill.id;
      });
      setSkills([...auxSkills]);

      let currenUser = state.session.user;
      _.remove(currenUser.skillIds, (id) => {
        return id === skill["skill-id"];
      });
      state.setSession({ user: currenUser });
    }
  };

  const finishSubscription = () => {
    addSubscription()
      .then((response) => {
        if (response.error) {
          toast.error(response.error.title);
        } else {
          let currenUser = state.session.user;
          currenUser.subscriptionId = response.data.id;
          state.setSession({ user: currenUser });

          window.location.pathname = "finish_subscription";
          toast.success("Inscrição finalizada com sucesso");
        }
      })
      .catch((error) => {
        toast.error("Ops, houve um erro. Tente novamente");
        console.error(error);
      });
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <div className="card-arch">
      {confirmModal && <Modal rejectAction={() => setConfirmModal(false)} acceptAction={() => finishSubscription()} />}
      {currentSkill && <SkillDocuments currentSkill={currentSkill} closeCurrentSkill={() => setCurrentSkill(null)} />}
      {!currentSkill && (
        <>
          <div className="side-content--transparent">
            {skills.length > 0 ? (
              skills?.map((skill, index) => (
                <div className="archive-card" key={index}>
                  <header className="archive-card__header">
                    <div className="archive-card__group">
                      <div className="archive-card__subtitle">{skill["skill-area"]}</div>
                      <h2 className="archive-card__title">
                        {skill["skill-name"]} <IoIosClose onClick={() => deleteUserSkill(skill)} size={24} color="#f23" />
                      </h2>
                    </div>
                  </header>
                  <div className="archive-card__body">
                    <p className={`archive-card__info ${skill["document-count"] === 0 ? "warning" : ""}`}>
                      {skill["document-count"]} <label>Arquivos</label>
                    </p>
                    <span className="archive-card__name">{moment(skill["updated-at"]).format("DD/MM/YYYY")}</span>
                  </div>
                  <div className="archive-card__footer">
                    <button className="btn btn--outline btn--small" onClick={() => setCurrentSkill(skill)}>
                      Selecionar documentos
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state">
                <div className="empty-state__title">Nenhuma aptidão selecionada!</div>
                <div className="empty-state__subtitle">Selecione até 3 aptidões na sessão anterior.</div>
              </div>
            )}
          </div>
          <div className="title-arch__buttonfinish">
            <div className="tip-message">
              <b>Atenção:</b> Antes de finalizar, verifique se foram anexados os documentos em cada uma das aptidões selecionadas. <br />
              Caso não haja documentação, o candidato deverá excluir a aptidão sem documento para finalizar a inscrição.
            </div>
            <button className="btn btn--secondary btn--xlarge" onClick={() => setConfirmModal(true)}>
              Finalizar minha inscrição
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ArchiveCard;
