import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../../UserContext'
import AdminSidebar from "../components/AdminSidebar";
import AdminMainNav from '../components/AdminMainNav';
import FilterBarResources from '../components/FilterBarResources';
import Preloader from '../components/Preloader'
import AdminResourceCard from '../components/AdminResourceCard'
import AdminResourceCard1 from '../components/AdminResourceCard1'
import AdminResourceCard2 from '../components/AdminResourceCard2'
import { FiCloudOff } from "react-icons/fi";
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';
import _ from 'lodash'

function AdminResources() {
  const client = useClient();
  const state = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [resources, setResources] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [filteredResources, setFilteredResources] = useState([]);

  useEffect(() => {
    fetchDataResources();
  }, []);

  const fetchDataResources = async () => {
    try {
      const { data, error } = await client.fetch('admin/resources')
      if(error && error.message === 'Not Authorized') {
        state.setAdmin(null)
      } else {
        setResources([...data])
        // setPendingCount( _.filter(data, (resource) => {return resource.status === 'sent' }).length )
        // setFilteredResources([...data])
        setTimeout(() => {
          setIsLoading(false)
          setFilterLoaded(true)
        }, 100);
      }
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const filterCallback = (selectedArea, selectedSkill, selectedStatus) => {
    setIsLoading(true)
    let auxResources = resources
    console.log(auxResources.length, selectedArea, selectedSkill, selectedStatus);

    if(selectedSkill) {
      auxResources = _.filter(auxResources, (r) => {return selectedSkill.id === r['skill-id'] });
    } else if (selectedArea) {
      auxResources = _.filter(auxResources, (r) => {return selectedArea.id === r['skill-area-id'] });
    }

    if(selectedStatus) {
      auxResources = _.filter(auxResources, (r) => {return selectedStatus === r.status });
    }

    setFilteredResources(auxResources)
    setPendingCount( _.filter(auxResources, (resource) => {return resource.status === 'sent' }).length )
    setTimeout(() => { setIsLoading(false) }, 100);
  }

  return (
    <div className="main-screen">
      <AdminSidebar activeMenu="admin-resources" />
      <AdminMainNav/>
      <div className="main-content">
        {filterLoaded && <FilterBarResources pendingCount={pendingCount} filterCallback={filterCallback} />}

        <div className="home-content">
          <div className="side-content--transparent">
            {/*<AdminResourceCard1/>
            <AdminResourceCard2/>*/}

            {isLoading ? <Preloader /> : (
              (filteredResources && filteredResources.length > 0) ? (
                filteredResources.map((resource, index) => (
                  <AdminResourceCard key={index} resource={resource} />
                ))
              ) : (
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35}/>
                    <div className="empty-state__title">Nenhum recurso encontrado</div>
                    <div className="empty-state__subtitle">Resete ou altere o filtro</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminResources;
