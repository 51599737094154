import React, { useState, useEffect, useContext } from 'react';
import { useClient } from 'jsonapi-react'
import ModalAdminInterview from './ModalAdminInterview'
import UserContext from '../../UserContext'
import InputMask from 'react-input-mask';
import toast  from 'react-hot-toast';
import _ from 'lodash'
import {FiFileText, FiCoffee, FiChevronLeft, FiCloudSnow} from 'react-icons/fi';

function UserInterview(props) {
  const {currentSkill} = props;
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState({
    comments: currentSkill.comments,
    status: currentSkill.status
  });

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  const openModal = (e) => {
    e.preventDefault();
    setConfirmModal(true)
  }

  const updateSkill = () => {
    const myPromise = client.mutate(['admin', 'user_skills', currentSkill.id], values)
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        toast.success("Status da aptidão atualizado!")
        setTimeout(() => {
          window.location.pathname = 'admin_schedules'
        }, 2000)
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`)
      }
    })
  }

  return (

    <div className="doc-list__container">
      {confirmModal && <ModalAdminInterview rejectAction={() => setConfirmModal(false)} acceptAction={() => updateSkill()} />}
      {currentSkill.status === 'approved_interview' ? (
        <>
          <div className="doc-list form">
            <textarea className="interview-form" id="comments" name="comments" placeholder="Observações da entrevista" value={values.comments} onChange={handleChange} />
          </div>
          <div className="doc-list__result">
            <div className="doc-list__result-title">Resultado da entrevista</div>
            <form className="doc-list__result-form form" onSubmit={openModal}>
              <div className="radio-button">
                <input type="radio" id="html" name="status" value="approved" checked={values.status === 'approved'} onChange={handleChange}/>
                <label for="html">Apto</label>
              </div>
              <div className="radio-button">
                <input type="radio" id="css" name="status" value="disapproved" checked={values.status === 'disapproved'} onChange={handleChange}/>
                <label for="css">Inapto</label>
              </div>
              <div className="form__row">
                <input type="submit" value="Salvar e voltar"/>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="pannel-content__ability">
          <div className="pannel-content__ability-header"> {currentSkill['skill-area']} <b>{currentSkill['skill-name']}</b></div>
          <div className="pannel-content__ability-status"> {currentSkill['t-status']} </div>
          <div className="pannel-content__documents">{currentSkill.comments}</div>
        </div>
      )}
    </div>
  );
}

export default UserInterview;
