import React, { useContext, useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import UserContext from '../../UserContext'
import AdminSidebar from "../components/AdminSidebar";
import Preloader from '../components/Preloader'
import AdminMainNav from '../components/AdminMainNav';
import FilterBarSchedule from '../components/FilterBarSchedule';
import { FiCloudOff } from "react-icons/fi";
import { Link } from "react-router-dom";
//import AdminScheduleCard from "../components/AdminScheduleCard";
//import AdminScheduleCard2 from "../components/AdminScheduleCard2";
//import AdminScheduleCard3 from "../components/AdminScheduleCard3";
import toast  from 'react-hot-toast';
import userAvatar from '../images/user-avatar.svg';
import _ from 'lodash'
import moment from 'moment';
import 'moment/locale/pt';


function AdminSchedules(){
  const client = useClient();
  const state = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [collectionLocations, setCollectionLocations] = useState([]);

  useEffect(() => {
    fetchDataSchedules();
  }, []);

  const fetchDataSchedules = async () => {
    try {
      const { data, error } = await client.fetch('admin/schedules')
      if(error && error.message === 'Not Authorized') {
        state.setAdmin(null)
      } else {
        setSchedules([...data])
        // setPendingCount( _.filter(data, (schedule) => {return schedule.status === 'pending' }).length )
        // setIsLoading(false)
        // setFilteredSchedules([...data])

        let locations = data.map((schedule) => schedule.address)
        locations = _.uniq(locations).sort()
        locations = _.without(locations, '')
        setTimeout(() => {
          setCollectionLocations(locations)
          setFilterLoaded(true)
        }, 100);
      }
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const filterCallback = (selectedArea, selectedSkill, selectedStatus, selectedDate, selectedLocation) => {
    setIsLoading(true)
    let auxSchedules = schedules
    // console.log(auxSchedules.length, selectedArea, selectedSkill, selectedStatus, selectedDate, selectedLocation);

    if(selectedSkill) {
      auxSchedules = _.filter(auxSchedules, (s) => {return selectedSkill.id === s['user-skill']['skill-id'] });
    } else if (selectedArea) {
      auxSchedules = _.filter(auxSchedules, (s) => {return selectedArea.id === s['user-skill']['skill-area-id'] });
    }

    if(selectedStatus) {
      auxSchedules = _.filter(auxSchedules, (s) => {return selectedStatus === s.status });
    }

    if(selectedDate) {
      auxSchedules = _.filter(auxSchedules, (s) => {return selectedDate === s.date });
    }

    if(selectedLocation) {
      auxSchedules = _.filter(auxSchedules, (s) => {return selectedLocation === s.address });
    }
    setFilteredSchedules(auxSchedules)
    setPendingCount( _.filter(auxSchedules, (userSkill) => {return userSkill.status === 'pending' }).length )
    setTimeout(() => { setIsLoading(false) }, 100);
  }


  return (
    <div className="main-screen">
      <AdminSidebar activeMenu="agendamentos" />
      <AdminMainNav/>
      <div className="main-content">
        <div className="home-content">
          {filterLoaded && <FilterBarSchedule pendingCount={pendingCount} filterCallback={filterCallback} collectionLocations={collectionLocations} />}
          <div className="admin-schedules">
            {/*<AdminScheduleCard/>
            <AdminScheduleCard2/>
            <AdminScheduleCard/>
            <AdminScheduleCard3/>*/}

            {isLoading ? <Preloader /> : (
              (filteredSchedules && filteredSchedules.length > 0) ? (
                filteredSchedules.map((schedule, index) => (
                  <div key={index} className="admin-schedule">
                    <div className="admin-schedule__when">
                      <div className="admin-schedule__date">{moment(schedule.date).format('DD / MM / YYYY')}</div>
                      <div className="admin-schedule__time">{moment(schedule.time).format('HH:mm')}</div>
                    </div>
                    <div className="admin-schedule__user">
                      <div className="admin-schedule__user-avatar" style={{ backgroundImage: `url(${schedule?.user?.avatar || userAvatar})`}}></div>
                      <div className="admin-schedule__user-info">
                        <div className="admin-schedule__user-info__name">{schedule?.user['full-name']}</div>
                        <div className="admin-schedule__user-info__register">{schedule?.user?.registration}</div>
                      </div>
                    </div>

                    {/*<div className="admin-schedule-title">{schedule['user-skill']['skill-area']}</div>
                    <div className="admin-schedule-subtitle">{schedule['user-skill']['skill-name']}</div>*/}
                    <div className="admin-schedule__adress">{schedule.address}</div>

                    <div className={`admin-schedule__accept-status ${schedule.confirmed ? 'confirmed' : 'pendent'}`}>{schedule.confirmed ? 'Confirmado' : 'Não Confirmado'}</div>
                    <div className={`admin-schedule__position ${schedule.status}`}>{schedule['t-status']}</div>
                    <div className="admin-schedule__action">
                      <Link to={`/user_docs/${schedule['user-skill']?.id}#entrevista`} className="btn btn--outline btn--small">Entrevista</Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35}/>
                    <div className="empty-state__title">Nenhum agendamento encontrado</div>
                    <div className="empty-state__subtitle">Resete ou altere o filtro</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

    </div>

  );
}

export default AdminSchedules;
