import React, { useContext, useState, useEffect } from "react";
import {FiFileText, FiCheckCircle } from 'react-icons/fi';
import _ from 'lodash'

function AdminResourceDocumentCard(props) {
  const {document} = props;

  return (
    <div className="archive-upload-card archive-upload-card--resource">
      <header className="archive-upload-card__header">
        <div className="archive-upload-card__iconsvg"><FiFileText size={27}/></div>
        <div className="archive-upload-card__checkicon"><FiCheckCircle size={18}/></div>
        <div className="archive-upload-card__group">
          <h2 className="archive-upload-card__title">{document.filename}</h2>
          <div className="archive-upload-card__filetype">{_.upperCase(document['file-extension'])}</div>
        </div>
      </header>
      <div className="archive-upload-card__footer">
        <button className="btn btn--outline btn--small btn--full"><a href={document.file} target='blank'>Visualizar</a></button>
      </div>
    </div>
  )
}

export default AdminResourceDocumentCard
