import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react'
import AdminSidebar from "../components/AdminSidebar";
import AdminMainNav from '../components/AdminMainNav';
import AdminResourceDocumentCard from '../components/AdminResourceDocumentCard'
import ModalAdminResource from '../components/ModalAdminResource'
import Preloader from '../components/Preloader'
import { FiChevronLeft } from "react-icons/fi";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import toast  from 'react-hot-toast';

function AdminResourcesForm(props) {
  const client = useClient();
  const resourceId = props?.computedMatch?.params?.id
  const [isLoading, setIsLoading] = useState(true);
  const [currentResource, setCurrentResource] = useState()
  const [resourceDocuments, setResourceDocuments] = useState([])
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    fetchCurrentResource();
    fetchResourceDocuments();
  }, []);

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  const fetchCurrentResource = async () => {
    try {
      const { data } = await client.fetch(`admin/resources/${resourceId}`)
      setCurrentResource(data)
      setValues({
        'admin-comment': data['admin-comment'],
        status: data.status
      })

      if(resourceDocuments) {
        setTimeout(() => { setIsLoading(false) }, 50)
      }
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const fetchResourceDocuments = async () => {
    try {
      const { data } = await client.fetch(`admin/resources/${resourceId}/resource_documents`)
      setResourceDocuments([...data])
      if(currentResource) {
        setTimeout(() => { setIsLoading(false) }, 50)
      }
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const openModal = (e) => {
    e.preventDefault();
    if(values.status && values.status !== 'sent') {
      setConfirmModal(true)
    } else {
      toast.error(`Selecione uma das opções`)
    }
  }

  const updateResource = () => {
    const myPromise = client.mutate(['admin', 'resources', resourceId], {
      'status': values['status']
    })
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        toast.success("Status do recurso atualizado!")
        setTimeout(() => {
          window.location.pathname = 'admin-resources'
        }, 2000)
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`)
      }
    })
  }

  const updateComment = (e) => {
    e.preventDefault();
    const myPromise = client.mutate(['admin', 'resources', resourceId], {
      'admin-comment': values['admin-comment']
    })
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        toast.success("Observação do recurso atualizado!")
        setTimeout(() => {
          window.location.pathname = 'admin-resources'
        }, 2000)
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`)
      }
    })
  }

  return (
    <div className="main-screen">
      <AdminSidebar activeMenu="admin-resources" />
      <AdminMainNav/>
      <div className="main-content">
        {confirmModal && <ModalAdminResource rejectAction={() => setConfirmModal(false)} acceptAction={() => updateResource()} />}
        {isLoading ? <Preloader /> : (
          <>
            <div className="title-arch__user-day">
              <div className="title-arch__button2">
                <Link to="/admin-resources" className="btn btn--outline btn--icon"><FiChevronLeft size={20}/>Voltar</Link>
              </div>
              <div className="title-arch__user-day-date"><div className="title-arch__user-name">{currentResource.user['full-name']}</div>{currentResource['skill-area']} &bull; {currentResource['skill-name']}</div>
            </div>
            <div className="admin-resource-review">
              <div className="resource-form__textarea text">
                <p>{ReactHtmlParser(currentResource['user-comment'].replaceAll('\n','<br/>'))}</p>
              </div>
              <div className="resource-form__document-list">
                {resourceDocuments && resourceDocuments.length > 0 && resourceDocuments.map((document, index) => (
                  <AdminResourceDocumentCard key={index} document={document} />
                ))}
              </div>
            </div>

            {currentResource.status === 'sent' ? (
              <>
                <form className="doc-list form" onSubmit={updateComment}>
                  <textarea className="interview-form" id="admin-comment" name="admin-comment" placeholder="Observações do recurso" value={values['admin-comment']} onChange={handleChange} />
                  <input type="submit" value="Salvar"/>
                </form>

                <div className="doc-list__result">
                  <div className="doc-list__result-title">Resultado do recurso</div>
                  <form className="doc-list__result-form form" onSubmit={openModal}>
                    <div className="radio-button">
                      <input type="radio" id="deferred" name="status" value="deferred" checked={values.status === 'deferred'} onChange={handleChange}/>
                      <label for="deferred">Deferido</label>
                    </div>
                    <div className="radio-button">
                      <input type="radio" id="rejected" name="status" value="rejected" checked={values.status === 'rejected'} onChange={handleChange}/>
                      <label for="rejected">Indeferido</label>
                    </div>
                    <div className="form__row">
                      <input type="submit" value="Finalizar"/>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <div className="pannel-content__ability">
                <div className="pannel-content__ability-header"> {currentResource['skill-area']} <b>{currentResource['skill-name']}</b></div>
                <div className="pannel-content__ability-status"> {currentResource['t-status']} </div>
                <div className="pannel-content__documents">{currentResource['admin-comment']}</div>
              </div>
            )}

          </>
        )}
      </div>
    </div>
  );
}

export default AdminResourcesForm;
