import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo-estudologia.png";
import useWindowSize from "../utils/WindowSize";

function Header() {
  const { width } = useWindowSize();
  return (
    <div className="header">
      <div className="header__container">
        <div className="header__title">Concessão de Aptidão - Estudologia</div>

        <img src={logo} />
      </div>
    </div>
  );
}

export default Header;
