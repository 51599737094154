import React, { useState, useContext } from "react";
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import UserContext from '../../UserContext'
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';
import _ from 'lodash'
import { FiFileText } from "react-icons/fi";

function MyInterview(props) {
  const state = useContext(UserContext)
  const client = useClient();

  const [values, setValues] = useState({
    name: state.session.user.fullName,
    email: state.session.user.email
  });

  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="home-content">
          <div className="side-content--donate">
            <div className="email-confirmation">
              <FiFileText size={35}/>
              <h1>PORTARIA Nº 435, DE 30 DE AGOSTO DE 2021</h1>
              <p>Acesse a portaria publicada no diário oficial no link abaixo.</p>
              <div className="title-arch__buttonemail">
                <a className="btn btn--primary" href="https://dodf.df.gov.br/index/visualizar-arquivo/?pasta=2021|08_Agosto|DODF%20077%2031-08-2021%20EDICAO%20EXTRA%20A|&arquivo=DODF%20077%2031-08-2021%20EDICAO%20EXTRA%20A.pdf" target="_blank">Acessar portaria</a>
                <br /><br />
                <a className="btn btn--primary" href="https://dodf.df.gov.br/index/visualizar-arquivo/?pasta=2021%7C09_Setembro%7CDODF%20168%2003-09-2021%7C&arquivo=DODF%20168%2003-09-2021%20INTEGRA.pdf" target="_blank">Atualização da portaria</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
export default MyInterview
