import React, { useState, useEffect, useContext } from "react";
import { useClient } from "jsonapi-react";
import UserContext from "../../UserContext";
import InputMask from "react-input-mask";
import ModalAdminDocument from "./ModalAdminDocument";
import toast from "react-hot-toast";
import _ from "lodash";
import { FiFileText, FiChevronLeft, FiCloudSnow } from "react-icons/fi";

function UserDocAnalysis(props) {
  const { currentSkill } = props;
  const state = useContext(UserContext);
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const { data } = await client.fetch(`admin/user_skills/${currentSkill?.id}/documents`);
      setDocuments([...data]);
      setIsLoading(false);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({ ...auxValues });
  };

  const updateDocument = (e, documentId) => {
    e.preventDefault();
    const nextStatus = e.target.value;
    const myPromise = client.mutate(["admin", "user_skills", currentSkill.id, "documents", e.target.id], { status: nextStatus });
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        let auxDocuments = documents;
        const selectedIndex = _.findIndex(auxDocuments, ["id", parseInt(documentId)]);
        auxDocuments[selectedIndex].status = nextStatus;
        setDocuments([...auxDocuments]);

        toast.success("Status do documento atualizado!");
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`);
      }
    });
  };

  const openModal = (e) => {
    e.preventDefault();
    setConfirmModal(true);
  };

  const updateSkill = () => {
    const myPromise = client.mutate(["admin", "user_skills", currentSkill.id], values);
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        toast.success("Status da aptidão atualizado!");
        setTimeout(() => {
          window.location.pathname = "admin_home";
        }, 2000);
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`);
      }
    });
  };

  return (
    <div className="doc-list__container">
      {confirmModal && <ModalAdminDocument rejectAction={() => setConfirmModal(false)} acceptAction={() => updateSkill()} />}

      {currentSkill.status === "pending" ? (
        <>
          <div className="doc-list">
            {documents?.map((document, index) => (
              <div className="archive-upload-card smaller">
                <header className="archive-upload-card__header">
                  <div className="archive-upload-card__iconsvg">
                    <FiFileText size={27} />
                  </div>
                  <div className="archive-upload-card__group">
                    <h2 className="archive-upload-card__title">{document.filename}</h2>
                    <div className="archive-upload-card__filetype">{_.upperCase(document["file-extension"])}</div>
                  </div>
                </header>

                <div className="archive-upload-card__footer">
                  <select id={document.id} value={document.status} name="status" onChange={(e) => updateDocument(e, document.id)}>
                    <option value="reviewing">Em análise </option>
                    <option value="deferred">Deferido</option>
                    <option value="rejected">Indeferido</option>
                  </select>
                  <button className="btn btn--outline btn--small">
                    <a href={document.file} target="blank">
                      Visualizar
                    </a>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="doc-list__result">
            <div className="doc-list__result-title">Resultado da análise</div>
            <form className="doc-list__result-form form" onSubmit={openModal}>
              <div className="radio-button">
                <input type="radio" id="html" name="status" value="approved_interview" onChange={handleChange} />
                <label for="html">Habilitado</label>
              </div>
              <div className="radio-button">
                <input type="radio" id="css" name="status" value="disabled" onChange={handleChange} />
                <label for="css">Não habilitado</label>
              </div>
              <div className="form__row">
                <input type="submit" value="Salvar e voltar" />
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="pannel-content__ability">
          <div className="pannel-content__ability-header">
            {" "}
            {currentSkill["skill-area"]} <b>{currentSkill["skill-name"]}</b>
          </div>
          <div className="pannel-content__ability-status"> {currentSkill["t-status"]} </div>
          <div className="pannel-content__documents">
            {documents?.map((document, index) => (
              <div className="pannel-content__document" key={document.id}>
                <a href={document.file} target="blank" className="pannel-content__document-title">
                  {/* {document.filename} */}
                  Documento
                </a>
                <div className={`pannel-content__document-status ${document.status}`}>{document["t-status"]}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDocAnalysis;
