import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext'
import { useClient } from 'jsonapi-react'
import MainSidebar from "../components/MainSidebar";
import MainNav from '../components/MainNav';
import ArchiveCard from '../components/ArchiveCard';
import PersonalData from '../components/PersonalData';
import AbilitiesCard from '../components/AbilitiesCard';
import { Redirect } from "react-router-dom";
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';
import Header from "../components/Header";

function Welcome(){

  return (
    <div className="welcome-screen">
      <Header/>
      <div className="empty-state home">
        <div className="empty-state__title">Processo de Aptidão De Servidores da Carreira Magistério Público do Distrito Federal 2021.</div>
        <div className="empty-state__subtitle">Em breve as inscrições poderão ser realizadas aqui.</div>
      </div>
    </div>

  );
}

export default Welcome;
