import React, { useContext, useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import UserContext from '../../UserContext'
import toast  from 'react-hot-toast';
import _ from 'lodash'

function FilterBarApproved(props) {
  const {filterCallback} = props;
  const client = useClient();
  const state = useContext(UserContext)
  const [skillAreas, setSkillAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(state.approvedFilters?.selectedArea);
  const [selectedSkill, setSelectedSkill] = useState(state.approvedFilters?.selectedSkill);

  useEffect(() => {
    fetchDataAbility();
    if(!selectedArea && !selectedSkill) {
      filterCallback(selectedArea, selectedSkill)
    }
  }, []);

  useEffect(() => {
    if(filterCallback && (selectedArea || selectedSkill)) {
      state.setApprovedFilters({
        selectedArea: selectedArea,
        selectedSkill: selectedSkill
      })
      filterCallback(selectedArea, selectedSkill)
    }
  }, [selectedArea, selectedSkill]);

  const fetchDataAbility = async () => {
    try {
      const { data } = await client.fetch('skill_areas')
      setSkillAreas([...data])
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const handleSelectArea = (e) => {
    const selectedIndex = _.findIndex(skillAreas, ['id', parseInt(e.target.value)]);
    setSelectedArea(skillAreas[selectedIndex])
    setSelectedSkill(null)
  };

  const handleSelectSkill = (e) => {
    const selectedIndex = _.findIndex(selectedArea.skills, ['id', parseInt(e.target.value)]);
    setSelectedSkill(selectedArea.skills[selectedIndex])
  };

  const clearFilter = () => {
    setSelectedArea(null)
    setSelectedSkill(null)
    filterCallback(null, null)
    state.setApprovedFilters(null)
  };

  return (
    <div className="filter-bar form">

      <div className="input__column">
        <label htmlFor="skillArea">Selecione a área de aptidão</label>
        <select id="skillArea" name="skillArea" value={selectedArea?.id || 'blank'} onChange={handleSelectArea}>
          <option value="blank">Selecione a área de aptidão</option>
          {skillAreas.map((skillArea, index) => (
            <option key={skillArea.id} value={skillArea.id}>{skillArea.name}</option>
          ))}
        </select>
      </div>

      <div className="input__column">
        <label htmlFor="skill">Selecione uma aptidão</label>
        <select id="skill" name="skill" value={selectedSkill?.id || 'blank'} onChange={handleSelectSkill}>
          <option value="blank">Selecione uma opção</option>
          {selectedArea && selectedArea?.skills.map((skill, index) => (
            <option key={skill.id} value={skill.id}>{skill.name}</option>
          ))}
        </select>
      </div>


      <div className="input__column small">
        <button className="btn btn--primary btn--small btn--outline" onClick={clearFilter}>Limpar filtro</button>
      </div>
    </div>
  )
}

export default FilterBarApproved
