import React, { useState, useEffect, useContext } from 'react';
import Preloader from './Preloader'
import { useClient } from 'jsonapi-react'
import {FiFileText, FiCheckCircle, FiChevronLeft, FiCloudSnow} from 'react-icons/fi';
import UserContext from '../../UserContext'
import toast  from 'react-hot-toast';
import _, { forEach, upperCase } from 'lodash'
import moment from 'moment';

function SkillDocuments(props) {
  const {currentSkill, closeCurrentSkill} = props
  const state = useContext(UserContext)
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({});
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetchDocuments();
  }, []);


  const fetchDocuments = async () => {
    try {
      const { data } = await client.fetch(`user_skills/${currentSkill.id}/documents`)
      setDocuments([...data])
      setIsLoading(false)
      console.log(data)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const handleChangeImage = (e) => {

    e.target.files.forEach(file => {
      let file_name = file.name
      let file_type = file.type

      if(file) {
        const reader = new FileReader()
        reader.onload = _handleReaderLoaded.bind(this, file_name, file_type)
        reader.readAsBinaryString(file)
      }

    });

    // let file_name = e.target.files[0].name
    // let file_type = e.target.files[0].type

  };

  const _handleReaderLoaded = (name, type, readerEvt) => {
    let bynaryString = readerEvt.target.result
    let auxValues = values;
    let fileName = name.split('.');
    auxValues['filename'] = fileName[0];
    auxValues['file'] = `data:${type};base64,${btoa(bynaryString)}`;
    setValues({...auxValues});
    document.getElementById('profileForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }))

  }

  const deleteDocument = async (document) => {
    console.log(document);
    const { error } = await client.delete(['user_skills', currentSkill.id, 'documents', document.id])

    if(error) {
      toast.error(error)
      console.log(error);
    } else {
      let auxDocument = documents
      _.remove(auxDocument, (val) => { return val.id === document.id })
      setDocuments([...auxDocument]);
      toast.success("Arquivo exluído com sucesso!")
    }
  }

  const updateUser = (e) => {
    e.preventDefault();
    const myPromise = client.mutate(['user_skills', currentSkill.id, "documents"], values)
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        let auxDocuments = documents
        auxDocuments.push(data)
        setDocuments([...auxDocuments])
        toast.success("Arquivo anexado!")
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`)
      }
    })
  }

  return (
    isLoading ? <Preloader /> : (
    <>
      <form className="form" id="profileForm" method="post" onSubmit={(e) => updateUser(e)}>
        <div className="title-arch">
          <div className="title-arch__user-day">
            <div className="title-arch__button2">
              <button className="btn btn--outline btn--icon" onClick={closeCurrentSkill}>
                <FiChevronLeft size={20}/>Voltar
              </button>
            </div>
            <div className="title-arch__user-day-date"><div className="title-arch__user-name">{currentSkill['skill-name']}</div>Última alteração: {moment(currentSkill['updated-at']).format('DD/MM/YYYY')}</div>
          </div>
          <div className="title-arch__button-file">
            <label htmlFor="image">Anexar documentos</label>
            <input type="file" name="image" id="image" accept=".jpeg, .png, .jpg, .pdf" multiple onChange={handleChangeImage} />
            <p>Somente arquivos nas extensões: <br />".jpg", ".jpeg", ".png" e ".pdf"</p>
          </div>
        </div>
      </form>

      <div class="home-content">
        <div className="side-content--transparent" >
          {documents.length > 0 ? (
              <>
                {documents?.map((document, index) => (
                  <div className="archive-upload-card">
                      <header className="archive-upload-card__header">
                        <div className="archive-upload-card__iconsvg"><FiFileText size={27}/></div>
                        <div className="archive-upload-card__checkicon"><FiCheckCircle size={18}/></div>
                        <div className="archive-upload-card__group">
                          <h2 className="archive-upload-card__title">{document.filename}</h2>
                          <div className="archive-upload-card__filetype">{upperCase(document['file-extension'])}</div>
                        </div>
                        <div className="archive-upload-card__filesize"></div>
                      </header>
                      <div className="archive-upload-card__footer">
                        <button className="btn btn--outline btn--small" onClick={() => deleteDocument(document)}>Excluir</button>
                        <button className="btn btn--outline btn--small" ><a href={document.file} target='blank' className="main-nav__help">Visualizar</a></button>
                      </div>
                  </div>
                ))}

                <div class="archive-upload-card__cta">
                  <div class="btn btn--secondary" onClick={closeCurrentSkill}>Salvar e voltar</div>
                </div>
              </>
            ) : (
              <div class="home-content__banner">
                <FiCloudSnow size={80}/>
                <label>Nenhum documento enviado.</label>
                <div class="home-content__banner-cta">
                  <label htmlFor="image">Enviar documentos</label>
                  <input type="file" name="image" id="image" accept=".jpeg, .png, .jpg, .pdf" multiple onChange={handleChangeImage} />
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  ));
}

export default SkillDocuments;
