import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react'
import { Link } from 'react-router-dom';
import AdminSidebar from "../components/AdminSidebar";
import AdminMainNav from '../components/AdminMainNav';
import UserInterview from '../components/UserInterview';
import UserPersonalData from '../components/UserPersonalData';
import UserDocAnalysis from '../components/UserDocAnalysis';
import {FiFileText, FiCheckCircle, FiChevronLeft, FiCloudSnow} from 'react-icons/fi';
import toast  from 'react-hot-toast';

function UserDocs(props){
  const client = useClient();
  const userSkillId = props?.computedMatch?.params?.id
  const [defaultTab, activeMenu] = window.location.hash === "#entrevista" ? ["Entrevista", "agendamentos"] : ["Documentação", "habilitacoes"]
  const [activeTabs, setActiveTabs] = useState(defaultTab)
  const [currentSkill, setCurrentSkill] = useState()

  useEffect(() => {
    fetchCurrentSkill();
  }, []);

  const fetchCurrentSkill = async () => {
    try {
      const { data } = await client.fetch(`admin/user_skills/${userSkillId}`)
      setCurrentSkill(data)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  return (
    <div className="main-screen">
      <AdminSidebar activeMenu={activeMenu} />
      <AdminMainNav />
      <div className="main-content">
        {currentSkill && <div className="title-arch">
          <div className="title-arch__user-day">
            <div className="title-arch__button2">
              <Link to="/admin_schedules" className="btn btn--outline btn--icon"><FiChevronLeft size={20}/>Voltar</Link>
            </div>
            <div className="title-arch__user-day-date"><div className="title-arch__user-name">{currentSkill.user['full-name']}</div>{currentSkill['skill-name']} &bull; {currentSkill['skill-area']} </div>
          </div>
        </div>}
        <div className="home-content">
          <div className="tab-menu">
            <div className={`tab-link ${activeTabs === 'Dados pessoais' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Dados pessoais')}>Dados pessoais</label>
            </div>
            <div className={`tab-link ${activeTabs === 'Documentação' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Documentação')}>Documentação</label>
            </div>
            <div className={`tab-link ${activeTabs === 'Entrevista' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Entrevista')}>Entrevista</label>
            </div>
          </div>

          {currentSkill && activeTabs === 'Dados pessoais' && <UserPersonalData currentSkill={currentSkill} changeTab={setActiveTabs} />}
          {currentSkill && activeTabs === 'Documentação' && <UserDocAnalysis currentSkill={currentSkill} changeTab={setActiveTabs} />}
          {currentSkill && activeTabs === 'Entrevista' && <UserInterview currentSkill={currentSkill} />}
        </div>
      </div>
    </div>
  );
}

export default UserDocs;
