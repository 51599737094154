import React from "react";

function ModalAdminResource(props) {
  const { rejectAction, acceptAction } = props;

  return (
    <div className="modal">
      <div className="modal__container">
        <div className="modal__header">
          <div className="modal__header-title">Envio de recurso</div>
          <div className="modal__header-subtitle">Estudologia</div>
        </div>
        <div className="modal__body">Avaliador salve o texto final antes de finalizar. Tem certeza que deseja finalizar a análise deste recurso? Uma vez finalizado não é possível alterar.</div>
        <div className="modal__footer">
          <div className="btn btn--outline-danger" onClick={rejectAction}>
            Ainda não
          </div>
          <div className="btn btn--success" onClick={acceptAction}>
            Finalizar
          </div>
        </div>
      </div>
      <div className="modal__overlay"></div>
    </div>
  );
}

export default ModalAdminResource;
