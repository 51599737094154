import axios from "axios";
import { getToken } from "./auth";

const baseURL = () => {
 if (process.env.NODE_ENV === 'production'){
   return process.env.REACT_APP_PRODUCTION_URL
 } else {
   return process.env.REACT_APP_DEV_URL
 }
}

const api = axios.create({
  baseURL: baseURL()
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use((response) => {
  return response.data;
});

export default api;