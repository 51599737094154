import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import UserContext from "../../UserContext";
import * as FiIcons from "react-icons/fi";

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "main-sidebar__link active" : "main-sidebar__link",
      };
    }}
  />
);

function AdminSidebar(props) {
  const { activeMenu } = props;
  const history = useHistory();
  const state = useContext(UserContext);
  const [active, setActive] = useState(false);

  const mobileNavToggle = () => {
    setActive(!active);
  };

  return (
    <div className="main-sidebar main-sidebar--admin">
      <div className="main-sidebar__mobile-hero">
        <div className="main-sidebar__mobile-hero-brand"></div>
        <div className="main-sidebar__mobile-hero-menu" onClick={mobileNavToggle}>
          <FaIcons.FaBars color="#fff" />
        </div>
      </div>
      <div className={`main-sidebar__container ${active ? "active" : ""}`}>
        <div className="main-sidebar__brand">
          <label>GESTOR</label>
          <p>Concessão de Aptidão</p>
        </div>
        <NavLink to="/admin_home">
          <div className={`main-sidebar__link-name ${activeMenu === "habilitacoes" ? "activenav" : ""}`}>
            <FiIcons.FiHome className="main-sidebar__link-icon" />
            Habilitações
          </div>
        </NavLink>
        <NavLink to="/admin_schedules">
          <div className={`main-sidebar__link-name ${activeMenu === "agendamentos" ? "activenav" : ""}`}>
            <FiIcons.FiCoffee className="main-sidebar__link-icon" />
            Agendamentos
          </div>
        </NavLink>
        <NavLink to="/admin_aproved">
          <div className={`main-sidebar__link-name ${activeMenu === "resultadoPreliminar" ? "activenav" : ""}`}>
            <FiIcons.FiCheckCircle className="main-sidebar__link-icon" />
            Resultado final
          </div>
        </NavLink>
        <NavLink to="/admin-resources">
          <div className={`main-sidebar__link-name ${activeMenu === "admin-resources" ? "activenav" : ""}`}>
            <FiIcons.FiCheckCircle className="main-sidebar__link-icon" />
            Recursos
          </div>
        </NavLink>

        <div className="main-sidebar__bottom-links">
          <NavLink to="/admin">
            <div className="main-sidebar__link-name" onClick={() => state.setAdmin(null)}>
              <FiIcons.FiPower className="main-sidebar__link-icon" />
              Logout
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default AdminSidebar;
