import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';
import UserContext from '../../UserContext'
import InputMask from 'react-input-mask';
import api from '../services/api.js';
import { useMutation } from 'jsonapi-react'
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Register() {
  const [addUser] = useMutation('users')
  const [register, setRegister] = useState({});
  const [values, setValues] = useState({});
  const [valid, setValid] = useState({});
  const state = useContext(UserContext)

  if (state.session && Object.keys(state.session).length !== 0) { window.location.pathname = 'login' }

  useEffect(() => {
    setValid(valid > 0 ? 1 : 0)
  }, [valid]);

  const authenticateUser = (e) => {
    e.preventDefault();
    api.post('user_registrations', register).then(response => {
      let normalizedResponse = normalize(response);
      Object.keys(normalizedResponse).map((key) => {
        return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
      })

      let auxRegister = register;
      auxRegister.fullName = normalizedResponse.userRegistration.fullName
      auxRegister.userRegistrationId = normalizedResponse.userRegistration.id
      setRegister({...auxRegister})

      setValid(1);
    }).catch(error => {
      console.error(error)
      toast.error('Matricula ou CPF inválidos')
    });
  }

  const registerUser = (e) => {
    e.preventDefault();

    let auxValues = values;
    auxValues.userRegistrationId = register.userRegistrationId;
    setValues({...auxValues});

    if(values.password === values.passwordConfirmation) {
      addUser(values).then(response => {
        if(response.error) {
          toast.error(response.error.title)
        } else {
          const forcedResponse = {data: {id: response.data.id, type: 'user', attributes: response.data}}
          let normalizedResponse = normalize(forcedResponse);
          Object.keys(normalizedResponse).map((key) => {
            return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
          })

          state.setSession({user: normalizedResponse.user});
        }
      }).catch(error => {
        toast.error('Ops, houve um erro. Tente novamente')
        console.error(error)
      });
    } else {
      setValues({...values, password: '', passwordConfirmation: ''});
      toast.error('Senha e confirmação de senha não são iguais')
    }
  }

  const handleChangeRegister = (e) => {
    let auxValues = register;
    auxValues[e.target.name] = e.target.value;
    setRegister({...auxValues});
  };

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  const registerMain = () => {
    return (
      <form className="form" method="post" onSubmit={(e) => authenticateUser(e)}>
        <div className="form__row">
          <label htmlFor="cpf">CPF:</label>
          <InputMask mask="999.999.999-99" name="cpf" id="cpf" onChange={handleChangeRegister} placeholder="Insira seu CPF" />
        </div>

        <div className="form__row">
          <label htmlFor="registration">Matrícula:</label>
          <input type="text" name="registration" id="registration" onChange={handleChangeRegister} placeholder="Insira sua Matrícula" />
        </div>
        <div className="form__row">
          <input type="submit" value="Verificar" />
        </div>
      </form>
    );
  }

  const registerSecond = () => {
    return (
      <form className="form" method="post" onSubmit={(e) => registerUser(e)}>
       <div className="form__row">
          <label htmlFor="cpf">CPF:</label>
          <InputMask mask="999.999.999-99" name="cpf" id="cpf" value={register.cpf} readOnly />
        </div>

        <div className="form__row">
          <label htmlFor="registration">Matrícula:</label>
          <input type="text" name="registration" id="registration" value={register.registration} readOnly />
        </div>

        <div className="form__row">
          <label htmlFor="full_name">Nome Completo:</label>
          <input type="text" name="full_name" id="full_name" value={register.fullName} readOnly />
        </div>

        <div className="form__row">
          <label htmlFor="email">E-mail:</label>
          <input type="email" name="email" id="email" placeholder="Insira seu E-mail" onChange={handleChange} />
        </div>

        <div className="form__row">
          <label htmlFor="phone">Telefone:</label>
          <InputMask mask="(99) 99999-9999" name="phone" id="phone" placeholder="Insira seu Telefone" onChange={handleChange} />
        </div>

        <div className="form__row">
          <label htmlFor="password">Senha:</label>
          <input type="password" name="password" id="password" placeholder="Insira uma senha" onChange={handleChange} />
        </div>

        <div className="form__row">
          <label className="passwordConfirmation">Confirme a senha:</label>
          <input type="password" name="passwordConfirmation" id="passwordConfirmation" placeholder="Insira a senha novamente" onChange={handleChange} />
        </div>
        <div className="form__row checkbox-wrap">
          <input type="checkbox" id="scales" name="scales" checked/>
          <label htmlFor="scales">Declaro estar ciente e de acordo com as <Link to="/terms" target="_blank">normas para o processo de concessão de aptidão aos servidores integrantes da Carreira Magistério Público do Distrito Federal</Link></label>
        </div>
        <div className="form__row">
          <input type="submit" value="Criar conta" />
        </div>
      </form>
    );
  }

  return (
    <div className="register">
      <Header />
      <div className="login__screen">
      <div className="register__form">
        <h1>Crie sua conta</h1>
        {(valid !== 1) ?  registerMain() : ((valid == 1) ? registerSecond() : toast.error('Matricula ou CPF inválidos'))}

        <div className="form__row back-login">
          Já tem uma conta? <br/><Link to="/login">Clique aqui e acesse.</Link>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Register
