import React, { useState, useEffect } from 'react';
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import Preloader from '../components/Preloader'
import { FiCloudOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useClient } from 'jsonapi-react'
import toast  from 'react-hot-toast';

function MyInterview(props) {
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [skills, setSkills] = useState([])
  const [skillAreaResults, setSkillAreaResults] = useState([])

  useEffect(() => {
    fetchDataAbility();
    fetchDataSkillAreaResults();
  }, []);

  const fetchDataAbility = async () => {
    try {
      const { data } = await client.fetch('user_skills')
      setSkills([...data])
      setIsLoading(false)
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }


    const fetchDataSkillAreaResults = async () => {
      try {
        const { data } = await client.fetch('admin/user_skills_results')
        setSkillAreaResults([...data])
        setTimeout(() => {
          setIsLoading(false)
        }, 100);
      } catch (e) {
        toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
        console.log(e);
      }
    }

  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        {/*<div className="main-content__header">
          <div className="email-instructions">
            <p><b>Atenção:</b> Este é um resultado preliminar, o resultado final está com a publicação prevista para o dia 03/10/2021. Caso haja a necessidade de interpor recurso clique na aba recurso.</p>
          </div>
        </div>*/}
        <div className="pannel-content__result">
          {isLoading ? <Preloader /> : (
            (skills && skills.length > 0) ? (
              skills.map((skill, index) => (
                <div className="pannel-content__ability" key={skill.id}>
                  <div className="pannel-content__ability-header"> {skill['skill-area']} <b>{skill['skill-name']}</b></div>
                  <div className={`pannel-content__ability-status ${skill.status}`}>
                    <div className="pannel-content__ability-status-text"> {skill['t-status']}</div>
                    {skill.status === 'disapproved' && <Link to="/resources" className="btn btn-call btn--small">Meus Recursos</Link>}
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state empty-state--full">
                <div className="empty-state__body">
                  <FiCloudOff size={35}/>
                  <div className="empty-state__title">Nenhum resultado encontrado</div>
                </div>
              </div>
            )
          )}
        </div>
        <br />
        <div className="pannel-content__result">
          <h1>Lista de aprovados:</h1>
          {isLoading ? <Preloader /> : (
            (skillAreaResults && skillAreaResults.length > 0) ? (
              skillAreaResults.map((skill) => (
                <div className="result-card" key={skill.id}>
                  <div className="result-card__head">{skill['skill-area-name']}</div>
                  <div className="result-card__sidebar">
                    <div className="result-card__sidebar-title">Halidade ou Aptidão</div>
                    <div className="result-card__sidebar-subtitle">{skill.name}</div>
                  </div>
                  <div className="result-card__body">
                    <div className="result-card__body-row">
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Nome</div>
                      </div>
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Matrícula</div>
                      </div>
                      <div className="result-card__body-collumn">
                        <div className="result-card__body-collumn__title">Resultado final</div>
                      </div>
                    </div>
                    {skill['user-skills'] && skill['user-skills'].map((userSkill) => (
                      <div className="result-card__body-row data-row" key={userSkill.id}>
                        <div className="result-card__body-collumn">{userSkill.user['full-name']}</div>
                        <div className="result-card__body-collumn">{userSkill.user.registration}</div>
                        <div className="result-card__body-collumn">{userSkill['t-status']}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="admin-schedules">
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35}/>
                    <div className="empty-state__title">Nenhum resultado encontrado</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

    </div>
  )
}
export default MyInterview
