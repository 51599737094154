const schema = {
  users: {
    type: 'users',
    fields: {
      registration: 'string',
      email: 'string',
      password: 'string',
      full_name: 'string',
      cpf: 'string',
      phone: 'string',
      work_shift: 'string',
      regional_coordination_id: 'string',
      lotacao: 'string'
    }
  }
}

export default schema