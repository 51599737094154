import React, { useState, useEffect, useContext } from "react";
import { useClient } from "jsonapi-react";
import normalize from "json-api-normalizer";
import UserContext from "../../UserContext";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";
import _ from "lodash";

function PersonalData(props) {
  const state = useContext(UserContext);
  const client = useClient();
  const [regionalCoordinations, setRegionalCoordinations] = useState([]);
  const shiftWorks = [
    { name: "Matutino", value: "morning" },
    { name: "Vespertino", value: "evening" },
  ];

  const [values, setValues] = useState({
    fullName: state.session.user.fullName,
    email: state.session.user.email,
    cpf: state.session.user.cpf,
    phone: state.session.user.phone,
    registration: state.session.user.registration,
    lotacao: state.session.user.lotacao,
    regionalCoordinationId: state.session.user.regionalCoordinationId,
    workShift: state.session.user.workShift,
  });

  useEffect(() => {
    fetchDataRegional();
  }, []);

  const fetchDataRegional = async () => {
    try {
      const { data } = await client.fetch("regional_coordinations");
      setRegionalCoordinations([...data]);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({ ...auxValues });
  };

  const updateUser = (e) => {
    e.preventDefault();
    const myPromise = client.mutate(["users", state.session.user.id], values);
    toast.promise(
      myPromise,
      {
        loading: "Carregando",
        success: "",
        error: "Ops, houve um erro. Tente novamente",
      },
      {
        success: { duration: -1, icon: null },
      }
    );

    myPromise.then((response) => {
      const { data, error } = response;
      if (data) {
        const forcedResponse = { data: { id: data.id, type: "user", attributes: data } };
        let normalizedResponse = normalize(forcedResponse);
        Object.keys(normalizedResponse).map((key) => {
          return (normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes);
        });
        normalizedResponse.user.authToken = state.session.user?.authToken;
        normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate;
        state.setSession({ user: normalizedResponse.user });
        toast.success("Dados atualizados!");
        props.changeTab("Aptidões");
      } else {
        toast.error(`Erro ao atualizar, ${error.id} ${error.title}`);
      }
    });
  };

  return (
    <div className="side-content--donate">
      <div className="side-content__title">Para continuar, complete seu cadastro</div>
      <br></br>
      <div className="side-content__subtitle"></div>

      {/*<form className="form__donate" method="post">*/}
      <form className="form__donate" method="post" onSubmit={(e) => updateUser(e)}>
        <div className="form__row">
          <label htmlFor="fullName">Nome Completo</label>
          <input type="text" name="fullName" id="fullName" placeholder="Insira seu nome completo" value={values.fullName} readOnly />
        </div>
        <div className="form__donate__divcard">
          <div className="form__donate__divcard__input1">
            <label htmlFor="cpf">CPF</label>
            <InputMask type="text" mask="999.999.999-99" name="cpf" id="cpf" placeholder="Insira seu CPF" value={values.cpf} readOnly />
          </div>
          <div className="form__donate__divcard__input2">
            <label htmlFor="matricula">Matrícula</label>
            <input type="text" name="matricula" id="matricula" placeholder="Matrícula" value={values.registration} readOnly />
          </div>
        </div>
        <div className="form__donate__divcard">
          <div className="form__donate__divcard__input1">
            <label htmlFor="regionalCoordinationId">CRE de lotação</label>
            <select name="regionalCoordinationId" id="regionalCoordinationId" value={values.regionalCoordinationId} onChange={handleChange}>
              <option>CRE</option>
              {regionalCoordinations.map((regionalCoordination, index) => (
                <option key={index} value={regionalCoordination.id}>
                  {regionalCoordination.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form__donate__divcard__input2">
            <label htmlFor="lotacao">Lotação</label>
            <input type="text" name="lotacao" id="lotacao" placeholder="Insira sua lotação" onChange={handleChange} value={values.lotacao} />
          </div>
        </div>
        <div className="form__donate__divcard">
          <div className="form__donate__divcard__input1">
            <label htmlFor="phone">Telefone</label>
            <InputMask type="text" mask="(99) 99999-9999" name="phone" id="phone" placeholder="Telefone" value={values.phone} onChange={handleChange} />
          </div>

          <div className="form__donate__divcard__input2">
            <label htmlFor="workShift">Turno para entrevista</label>
            <select name="workShift" id="workShift" value={values.workShift} onChange={handleChange}>
              <option>Turno</option>
              {shiftWorks &&
                shiftWorks.map((shiftWork, index) => (
                  <option key={index} value={shiftWork.value}>
                    {shiftWork.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="form__row">
          <input className="btn btn--full btn--large" type="submit" value="Salvar e prosseguir" />
        </div>
      </form>
    </div>
  );
}

export default PersonalData;
