import React, { useContext } from "react";

import userAvatar from "../images/user-avatar.svg";
import helpIcon from "../images/icons/help-icon.svg";
import calendarIcon from "../images/icons/calendar-icon.svg";
import UserContext from "../../UserContext";
import moment from "moment";
import "moment/locale/pt";

function Post(props) {
  const state = useContext(UserContext);
  // const objectiveDate = state.session?.objective?.date

  return (
    <div className="main-nav">
      <div className="main-nav__user">
        {/* <div className="main-nav__user-avatar" style={{ backgroundImage: `url(${state.session.user.image.url || userAvatar})`}}> </div> */}
        <div className="main-nav__user-avatar" style={{ backgroundImage: `url(${state?.session?.user?.avatar || userAvatar})` }}>
          {" "}
        </div>
        <div className="main-nav__user-body">
          <div className="main-nav__user-name"> {state.session && state.session.user.fullName} </div>

          <div className="main-nav__user-day">
            <div className="main-nav__user-day-icon">
              <img alt="" src={calendarIcon} />
            </div>
            <div className="main-nav__user-day-date">{moment().format("DD/MM/YYYY")}</div>
          </div>
        </div>
      </div>
      <div className="main-nav__links">
        <a href="mailto:suporte@suporte-plataforma.com.br" target="blank" className="main-nav__help">
          <div className="main-nav__help-icon">
            <img alt="" src={helpIcon} />
          </div>
          <div className="main-nav__help-text">Precisa de ajuda?</div>
        </a>
      </div>
    </div>
  );
}

export default Post;
