import React, { useState, useEffect, useContext } from 'react';
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import UserContext from '../../UserContext'
import InputMask from 'react-input-mask';
import { Redirect } from "react-router-dom";
import photoIcon from '../images/icons/photo-icon.svg';
import userAvatar from '../images/user-avatar.svg';
import { useClient } from 'jsonapi-react'
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';

function Profile(props) {
  const state = useContext(UserContext)
  const client = useClient();
  const [regionalCoordinations, setRegionalCoordinations] = useState([]);
  const [currenUser, setCurrenUser] = useState(state.session.user);
  const [values, setValues] = useState({
    fullName: state.session.user.fullName,
    email: state.session.user.email,
    cpf: state.session.user.cpf,
    phone: state.session.user.phone,
    registration: state.session.user.registration,
    lotacao: state.session.user.lotacao,
    regionalCoordinationId: state.session.user.regionalCoordinationId,
    workShift: state.session.user.workShift
  });

  useEffect(() => {
    fetchDataRegional();
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const { data } = await client.fetch(`users/${currenUser.id}`)
      const forcedResponse = {data: {id: data.id, type: 'user', attributes: data}}
      let normalizedResponse = normalize(forcedResponse);
      Object.keys(normalizedResponse).map((key) => {
        return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
      })
      normalizedResponse.user.authToken = state.session.user?.authToken
      normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate
      if(normalizedResponse.user?.avatar?.url) {
        normalizedResponse.user.avatar.url = `${normalizedResponse.user.avatar.url}?t=${(new Date()).getTime()}`
      }

      setCurrenUser(normalizedResponse.user)
      state.setSession({user: normalizedResponse.user});
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const fetchDataRegional = async () => {
    try {
      const { data } = await client.fetch('regional_coordinations')
      setRegionalCoordinations([...data])
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const updateUser = (e) => {
    e.preventDefault();
    const myPromise = client.mutate(['users', state.session.user.id], values)
    toast.promise(myPromise, {
      loading: 'Carregando',
      success: '',
      error: 'Ops, houve um erro. Tente novamente',
    }, {
      success: { duration: -1, icon: null }
    });

    myPromise.then((response) => {
      const { data, error } = response
      if(data) {
        const forcedResponse = {data: {id: data.id, type: 'user', attributes: data}}
        let normalizedResponse = normalize(forcedResponse);
        Object.keys(normalizedResponse).map((key) => {
          return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
        })
        normalizedResponse.user.authToken = state.session.user?.authToken
        normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate
        if(normalizedResponse.user?.avatar?.url) {
          normalizedResponse.user.avatar.url = `${normalizedResponse.user.avatar.url}?t=${(new Date()).getTime()}`
        }

        state.setSession({user: normalizedResponse.user});
        toast.success("Perfil atualizado!")
      } else {
        toast.error(`Ops, ${error?.id} ${error?.title}`)
      }
    })
  }

  const handleChange = (e) => {
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({...auxValues});
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0]
    const filename = e.target.files[0].name
    const extension = e.target.files[0].type

    if(file) {
      const reader = new FileReader()
      reader.onload = _handleReaderLoaded.bind(this, filename, extension)
      reader.readAsBinaryString(file)
    }
  };

  const _handleReaderLoaded = (filename, extension, readerEvt) => {
    let bynaryString = readerEvt.target.result
    let auxValues = values;
    auxValues['avatar'] = `data:${extension};base64,${btoa(bynaryString)}`;
    auxValues['avatar_filename'] = filename;
    setValues({...auxValues});
    document.getElementById('profileForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }))

  }

  if(currenUser?.subscriptionId !== null) { return <Redirect noThrow to="/finish_subscription" /> }
  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="profile-page__sidebar">
          <div className="profile-page__avatar" style={{ backgroundImage: `url(${state?.session?.user?.avatar || userAvatar})`}}></div>
          <label htmlFor="avatar" className="profile-page__avatar-btn">
            <img alt="" src={photoIcon}/>
            <input type="file" name="avatar" id="avatar" accept=".jpeg, .png, .jpg" onChange={handleChangeImage} />
          </label>
          <p className="profile-page__info">Estamos adequados à LGPD e boas práticas sobre a divulgação de dados e afins.</p>
        </div>

        <div className="profile-page__content">
          <form className="form" id="profileForm" method="post" onSubmit={(e) => updateUser(e)}>
            <div className="form__row">
              <label htmlFor="fullName">Nome Completo:</label>
              <input type="text" name="fullName" id="fullName" value={values.fullName} readOnly />
            </div>

            <div className="form__donate__divcard">
              <div className="form__donate__divcard__input1">
                <label htmlFor="cpf">CPF</label>
                <InputMask type="text" mask="999.999.999-99" name="cpf" id="cpf" placeholder="Insira seu CPF" value={values.cpf} readOnly/>
              </div>
              <div className="form__donate__divcard__input2">
                <label htmlFor="matricula">Matrícula</label>
                <input type="text" name="matricula" id="matricula" placeholder="Matrícula" value={values.registration} readOnly/>
              </div>
            </div>

            <div className="form__row">
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" id="email" value={values.email} onChange={handleChange} />
            </div>

            <div className="form__donate__divcard">
              <div className="form__donate__divcard__input1">
                <label htmlFor="phone">Telefone</label>
                <InputMask type="text" mask="(99) 99999-9999" name="phone" id="phone" placeholder="Telefone" value={values.phone} onChange={handleChange} />
              </div>
              <div className="form__donate__divcard__input2">
              <label htmlFor="regionalCoordinationId">CRE de lotação</label>
              <select name="regionalCoordinationId" id="regionalCoordinationId" value={values.regionalCoordinationId} onChange={handleChange} >
                <option>CRE</option>
                {regionalCoordinations.map((regionalCoordination, index) => (
                  <option key={index} value={regionalCoordination.id}>{regionalCoordination.name}</option>
                ))}
              </select>
              </div>
            </div>

            <div className="form__row">
              <label htmlFor="lotacao">Lotação</label>
              <input type="text" name="lotacao" id="lotacao" placeholder="Insira sua lotação" onChange={handleChange} value={values.lotacao}/>
            </div>

            <div className="form__row">
              <input type="submit" value="Salvar" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Profile
