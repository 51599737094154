import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext'
import { useClient } from 'jsonapi-react'
import { Link } from 'react-router-dom';
import MainNav from '../components/MainNav'
import MainSidebar from '../components/MainSidebar'
import { AiOutlineMail } from 'react-icons/ai';
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';

function EmailConfirmation(props) {
  const state = useContext(UserContext)
  const client = useClient();
  const [currenUser, setCurrenUser] = useState(state.session.user);
  const [disabledCard, setDisabledCard] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const resendEmail = (e) => {
    e.preventDefault();
    client.mutate('resend_confirmation').then(response => {
      toast.success('Email reenviado')
      setDisabledCard(true)
    }).catch(error => {
      console.error(error)
      toast.error('Erro ao tentar reenviar o email, tente novamente mais tarde')
    });
  }

  const getUserData = async () => {
    try {
      const { data } = await client.fetch(`users/${currenUser.id}`)
      const forcedResponse = {data: {id: data.id, type: 'user', attributes: data}}
      let normalizedResponse = normalize(forcedResponse);
      Object.keys(normalizedResponse).map((key) => {
        return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
      })
      normalizedResponse.user.authToken = state.session.user?.authToken
      normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate
      if(normalizedResponse.user?.avatar?.url) {
        normalizedResponse.user.avatar.url = `${normalizedResponse.user.avatar.url}?t=${(new Date()).getTime()}`
      }

      setCurrenUser(normalizedResponse.user)
      state.setSession({user: normalizedResponse.user});
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }


  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="home-content">
          <div className="side-content--donate">
            {!currenUser?.confirmedAt ? (
              <div className="email-confirmation">
                <AiOutlineMail size={35}/>
                <h1>Você precisa confirmar o seu email antes de continuar</h1><br/><br/><br/>
                <p>Enviamos um email de confirmação para <b>{currenUser.email}</b>, por favor confirme seu email para continuar</p>
                <strong>•<label>Atenção: o email pode cair na caixa de SPAM</label></strong>
                <br/><br/>
                <strong>•<label>Atenção: o seu email está errado? </label></strong>
                <div className="email-instructions">
                  <p>1 - Clique para <Link to="/profile" >atualizar seu email</Link></p>
                  <p>2 - Clique para <Link to="#" className={`${disabledCard ? 'disabled' : ''}`} onClick={resendEmail}> reenviar o email de confirmação</Link></p>
                </div>
              </div>
            ) : (
              <div className="email-confirmation">
                <AiOutlineMail size={35}/>
                <h1>Seu email foi confirmado!</h1>
                <p>Antes de continuar com a sua inscrição, clique no botão abaixo.</p>
                <div className="title-arch__buttonemail">
                  <a className="btn btn--primary" href="/">Começar minha inscrição</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}
export default EmailConfirmation
