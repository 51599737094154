import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext'
import { useClient } from 'jsonapi-react'
import MainSidebar from "../components/MainSidebar";
import MainNav from '../components/MainNav';
import ArchiveCard from '../components/ArchiveCard';
import PersonalData from '../components/PersonalData';
import AbilitiesCard from '../components/AbilitiesCard';
import { Redirect } from "react-router-dom";
import normalize from 'json-api-normalizer';
import toast  from 'react-hot-toast';

function Home(){
  const state = useContext(UserContext)
  const client = useClient();
  const [activeTabs, setActiveTabs] = useState('Dados pessoais')
  const [currenUser, setCurrenUser] = useState(state.session.user);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const { data } = await client.fetch(`users/${currenUser.id}`)
      const forcedResponse = {data: {id: data.id, type: 'user', attributes: data}}
      let normalizedResponse = normalize(forcedResponse);
      Object.keys(normalizedResponse).map((key) => {
        return normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes
      })
      normalizedResponse.user.authToken = state.session.user?.authToken
      normalizedResponse.user.tokenExpirationDate = state.session.user?.tokenExpirationDate
      if(normalizedResponse.user?.avatar?.url) {
        normalizedResponse.user.avatar.url = `${normalizedResponse.user.avatar.url}?t=${(new Date()).getTime()}`
      }

      setCurrenUser(normalizedResponse.user)
      state.setSession({user: normalizedResponse.user});
    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  if(!currenUser?.confirmedAt) { return <Redirect noThrow to="/confirmacao-de-email" /> }
  if(currenUser?.subscriptionId !== null) { return <Redirect noThrow to="/finish_subscription" /> }

  return (
    <div className="main-screen">
      <MainSidebar/>
      <MainNav/>
      <div className="main-content">
        <div className="home-content">
          <div className="tab-menu">
            <div className={`tab-link ${activeTabs === 'Dados pessoais' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Dados pessoais')}>1 • Dados Pessoais</label>
            </div>
            <div className={`tab-link ${activeTabs === 'Aptidões' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Aptidões')}>2 • Aptidões</label>
            </div>
            <div className={`tab-link ${activeTabs === 'Documentação' ? 'active' : ''}`}>
              <label onClick={() => setActiveTabs('Documentação')}>3 • Documentação</label>
            </div>
          </div>
        </div>

        {activeTabs === 'Dados pessoais' && <PersonalData changeTab={setActiveTabs} />}
        {activeTabs === 'Aptidões' && <AbilitiesCard changeTab={setActiveTabs} />}
        {activeTabs === 'Documentação' && <ArchiveCard changeTab={setActiveTabs} />}
      </div>
    </div>

  );
}

export default Home;
