import React, { useState, useEffect } from "react";
import MainNav from "../components/MainNav";
import MainSidebar from "../components/MainSidebar";
import Preloader from "../components/Preloader";
import { FiCloudOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useClient } from "jsonapi-react";
import toast from "react-hot-toast";

function Resources(props) {
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    fetchDataResources();
  }, []);

  const fetchDataResources = async () => {
    try {
      const { data, error } = await client.fetch("resources");
      setResources([...data]);
      setIsLoading(false);
    } catch (e) {
      toast.error("Ops, houve um erro. Tente novamente em alguns minutos");
      console.log(e);
    }
  };

  return (
    <div className="main-screen">
      <MainSidebar />
      <MainNav />
      <div className="main-content">
        <div className="main-content__header">
          <div className="email-instructions">
            <p>
              <b>Atenção:</b> O servidor interessado poderá interpor recurso para qualquer fase do processo (análise documental e banca de entrevista), uma única vez, junto à Subsecretaria de Educação Básica ou à Subsecretaria de Educação Inclusiva e
              Integral a depender da área pleiteada, até o dia 27/09.
              <br />
              <br />
              O servidor deverá preencher o recurso de forma clara, objetiva e consistente e fará suas alegações e considerações acerca da avaliação a que foi submetido, podendo anexar documentos que embasam suas colocações.
              <br />
              <br />O recurso será analisado, inicialmente, pela banca examinadora para o exercício do poder de reconsideração. Uma vez não reconsiderada a decisão da banca examinadora, o recurso será submetido à decisão final da SUBIN, para casos da
              Educação Especial, Centro Interescolar de Línguas, Escola Parque, Escola da Natureza e Educação em Tempo Integral (Ensino Médio em Tempo Integral e Programa Intercultural Bilíngue) e da SUBEB, nos demais casos.
            </p>
          </div>
        </div>
        <div className="side-content--transparent">
          {isLoading ? (
            <Preloader />
          ) : resources && resources.length > 0 ? (
            resources.map((resource, index) => (
              <div key={index} className="archive-card">
                <header className="archive-card__header">
                  <div className="archive-card__group">
                    <div className="archive-card__subtitle">{resource["skill-area"]}</div>
                    <h2 className="archive-card__title">{resource["skill-name"]}</h2>
                  </div>
                </header>
                <div className="archive-card__body">
                  <p className={`archive-card__info ${resource.status}`}>
                    <label>{resource["t-status"]}</label>
                  </p>
                </div>
                <div className="archive-card__footer">
                  {resource.status === "created" ? (
                    /*<div className="btn btn--outline btn--small">Indisponível</div>*/
                    <Link to={`/resources/${resource.id}`} className="btn btn--outline btn--small">
                      Enviar recurso
                    </Link>
                  ) : (
                    <Link to={`/resources/${resource.id}`} className="btn btn--outline-success btn--full btn--small">
                      Recurso Enviado
                    </Link>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="empty-state empty-state--full">
              <div className="empty-state__body">
                <FiCloudOff size={35} />
                <div className="empty-state__title">Nenhum recurso encontrado</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Resources;
