import React from "react";

function ModalAdminInterview(props) {
  const { rejectAction, acceptAction } = props;

  return (
    <div className="modal">
      <div className="modal__container">
        <div className="modal__header">
          <div className="modal__header-title">Envio de entrevista</div>
          <div className="modal__header-subtitle">Estudologia</div>
        </div>
        <div className="modal__body">Tem certeza que deseja salvar o resultado da entrevista? Uma vez salvo não é possível editar.</div>
        <div className="modal__footer">
          <div className="btn btn--outline-danger" onClick={rejectAction}>
            Ainda não
          </div>
          <div className="btn btn--success" onClick={acceptAction}>
            Salvar e enviar
          </div>
        </div>
      </div>
      <div className="modal__overlay"></div>
    </div>
  );
}

export default ModalAdminInterview;
