import React, { useContext, useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import UserContext from '../../UserContext'
import AdminSidebar from "../components/AdminSidebar";
import Preloader from '../components/Preloader'
import FilterBar from '../components/FilterBar';
import AdminMainNav from '../components/AdminMainNav';
import { Link } from "react-router-dom";
import toast  from 'react-hot-toast';
import userAvatar from '../images/user-avatar.svg';
import _ from 'lodash'
import { FiCloudOff } from "react-icons/fi";


function AdminHome() {
  const client = useClient();
  const state = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [userSkills, setUserSkills] = useState([]);
  const [filteredUserSkills, setFilteredUserSkills] = useState([]);

  useEffect(() => {
    fetchDataUserSkills();
  }, []);

  const fetchDataUserSkills = async () => {
    try {
      const { data, error } = await client.fetch('admin/user_skills')
      if(error && error.message === 'Not Authorized') {
        state.setAdmin(null)
      } else {
        setUserSkills([...data])
        // setPendingCount( _.filter(data, (userSkill) => {return userSkill.status === 'pending' }).length )
        // setIsLoading(false)
        // setFilteredUserSkills([...data])
        setTimeout(() => {
          setFilterLoaded(true)
        }, 100);
      }

    } catch (e) {
      toast.error('Ops, houve um erro. Tente novamente em alguns minutos')
      console.log(e);
    }
  }

  const filterCallback = (selectedArea, selectedSkill, selectedStatus) => {
    setIsLoading(true)
    // console.log(userSkills.length, selectedArea, selectedSkill, selectedStatus);

    let skills = userSkills
    if(selectedSkill) {
      skills = _.filter(skills, (s) => {return selectedSkill.id === s['skill-id'] });
    } else if (selectedArea) {
      skills = _.filter(skills, (s) => {return selectedArea.id === s['skill-area-id'] });
    }

    if(selectedStatus) {
      skills = _.filter(skills, (s) => {return selectedStatus === s.status });
    }
    setFilteredUserSkills(skills)
    setPendingCount( _.filter(skills, (userSkill) => {return userSkill.status === 'pending' }).length )
    setTimeout(() => { setIsLoading(false) }, 100);
  }

  return (
    <div className="main-screen">
      <AdminSidebar activeMenu="habilitacoes" />
      <AdminMainNav/>
      <div className="main-content">
        <div className="home-content">
          {filterLoaded && <FilterBar pendingCount={pendingCount} filterCallback={filterCallback} />}
          <div className="user-list">
            {isLoading ? <Preloader /> : (
              (filteredUserSkills && filteredUserSkills.length > 0) ? (
                filteredUserSkills.map((userSkill, index) => (
                  <div key={index} className="user-card">
                    <div className="user-card__header">
                      <div className="user-card__avatar" style={{ backgroundImage: `url(${userSkill?.user?.avatar || userAvatar})`}}></div>
                      <div className="user-card__info"> <b>{userSkill?.user['full-name']}</b> {userSkill['process-code']}</div>
                    </div>
                    <div className="user-card__body">
                      <div className="user-card__body-title">{userSkill['skill-area']}</div>
                      <div className="user-card__body-subtitle">{userSkill['skill-name']}</div>
                    </div>
                    <div className="user-card__footer">
                      <div className={`user-card__status ${userSkill.status}`}>{userSkill['t-status']}</div>
                      <Link to={`/user_docs/${userSkill.id}`}  className="btn btn--outline btn--small">Documentação</Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state empty-state--full">
                  <div className="empty-state__body">
                    <FiCloudOff size={35}/>
                    <div className="empty-state__title">Nenhuma inscrição encontrada</div>
                    <div className="empty-state__subtitle">Resete ou altere o filtro</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
